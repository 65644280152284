import { Injectable } from '@angular/core';
import {
  CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { SiteService } from './site.service';
import { SearchService } from './search.service';

@Injectable({
  providedIn: 'root'
})
export class SiteSelectGuard implements CanActivate {
  constructor(
    private site: SiteService,
    private searchService: SearchService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.site.site) {
      return true;
    }
    // Save the targeted url
    this.searchService.targetUrl = state.url;

    // Select a website
    this.searchService.searchOpen = true;
    console.log('failed!!');
    return false;
  }
}
