import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { SiteService } from './site.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private _currentRoute: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private title: Title,
    private alert: AlertService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
      .subscribe(data => {
        // Set title
        let title = 'Anytime';
        if (data.title) {
          this.translate.get(data.title)
            .subscribe(() => {
              title = this.translate.instant(data.title) + ' - ' + title;
              this.title.setTitle(title);
            });
        } else {
          this.title.setTitle(title);
        }

        this._currentRoute = {
          name: data.name || '',
          mainNoPadding: data.mainNoPadding || false,
          hideFooter: data.hideFooter || false
        };

        this.alert.clearTemp();
      });
  }

  get currentRoute(): any {
    return this._currentRoute;
  }
}
