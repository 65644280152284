import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Equipment } from '../models/equipment.model';
import { Rule } from '../models/rule.model';
import { ApiUrlService } from './api-url.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  private establishmentEquipments: Equipment[];

  private typologyEquipments: Equipment[];

  private establishmentRules: Rule[];

  private typologyRules: Rule[];


  constructor(
    private http: HttpClient,
    private apiUrl: ApiUrlService
  ) {

  }

  async initEquipmentsAndRules() {
    const { equipments, rules } = await this.http.get<any>(this.apiUrl.baseUrl() + 'equipments-rules').toPromise();

    this.establishmentEquipments = equipments.establishments;
    this.typologyEquipments = equipments.typologies;

    this.establishmentRules = rules.establishments;
    this.typologyRules = rules.typologies;
  }

  async getEstablishmentEquipments() {
    if (!this.establishmentEquipments) await this.initEquipmentsAndRules();
    return this.establishmentEquipments;
  }

  async getTypologyEquipments() {
    if (!this.typologyEquipments) await this.initEquipmentsAndRules();
    return this.typologyEquipments;
  }

  async getEstablishmentRules() {
    if (!this.establishmentRules) await this.initEquipmentsAndRules();
    return this.establishmentRules;
  }

  async getTypologyRules() {
    if (!this.typologyRules) await this.initEquipmentsAndRules();
    return this.typologyRules;
  }
}
