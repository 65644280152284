import { PipeTransform, Pipe } from '@angular/core';
import { SiteService } from '../services/site.service';
import { LanguageService } from '../services/language.service';
import { TranslationMap } from '../models/language-map.model';

@Pipe({ name: 'translateMap' })
export class TranslateMapPipe implements PipeTransform {
  constructor(private language: LanguageService) {

  }

  transform(translationMap: TranslationMap): string {
    return this.language.getTranslationFromMap(translationMap);
  }
}
