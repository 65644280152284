import {
  Component, OnInit, Input, Output, EventEmitter
} from '@angular/core';
import * as moment from 'moment';
import { ListingsApiService } from 'src/app/views/listings/listings-api.service';
import { CalendarApiService } from 'src/app/views/calendar/calendar-api.service';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditRangeModalContent } from 'src/app/views/calendar/calendar-creation/edit-range/edit-range.component';
import { Router } from '@angular/router';
import { Typology } from '../../models/typology.model';
import { Establishment } from '../../models/establishment.model';
import { CalendarDay } from '../../models/calendar-day.model';
import { LayoutService } from '../../services/layout.service';
import { CalendarService } from '../../services/calendar.service';
import { FormStepService } from '../../services/form-step.service';

@Component({
  selector: 'app-typology-calendar',
  templateUrl: './typology-calendar.component.html',
  styleUrls: ['./typology-calendar.component.scss']
})
export class TypologyCalendarComponent implements OnInit {
  lodash = _;

  /** Calendar item */
  @Input() calendarItem: { establishment: Establishment; calendar: any };

  /** List of days of the current date range (in YYYY-MM-DD format) */
  days: any[] = [];

  /** List of typologies of the current establishment */
  private typologies: Typology[] = [];

  /** The calendar corresponding to the establishment */
  calendar: any = [];

  /** Id of the global rate */
  globalRate: number = 1;

  /** Loading fields, to display a loader for each field on the view */
  loadingFields = {};

  /** Fields to set as loaded after calendar loading */
  private unsetAfterLoading: any[] = [];

  /** Current date range */
  private _dateRange: any;

  /** True if the component is being loaded */
  loading: boolean = false;

  /** True if the component has been loaded */
  private firstTimeLoaded: boolean = false;

  /** True if the typology has been loaded */
  typologyLoaded: boolean = false;

  private nextDateChange: any = null;

  constructor(
    private listingsApi: ListingsApiService,
    private calendarApi: CalendarApiService,
    private calendarService: CalendarService,
    private layout: LayoutService,
    private modalService: NgbModal,
    private formStepService: FormStepService,
    private router: Router
  ) {}

  get establishment() {
    return this.calendarItem.establishment;
  }

  ngOnInit() {}

  get dateRange() {
    return this._dateRange;
  }

  @Input() set dateRange(value: any) {
    this._dateRange = value;

    if (this.loading) {
      // Load after
      this.nextDateChange = value;
      return;
    }

    this.loading = true;
    this.days = [];

    const start = moment(this.dateRange.start).startOf('day');
    const end = moment(this.dateRange.end).startOf('day');

    // For each day in our range
    for (let m = start; m.diff(end, 'days') <= 0; m.add(1, 'days')) {
      this.days.push(m.format('YYYY-MM-DD'));
    }

    if (!this.firstTimeLoaded) {
      // The component is loaded for the first date
      this.firstTimeLoaded = true;

      this.typologies = [];
      for (const typo in this.calendarItem.calendar) {
        this.typologies.push(this.calendarItem.calendar[typo].typology);
      }
      this.typologyLoaded = true;

      this.calendarService.calendarUpdated.subscribe(calendar => {
        // New calendar change
        if (!calendar) {
          return;
        }
        return this.parseCalendar(calendar);
      });

      // // Also load typologies
      // this.listingsApi
      //     .getTypologies(this.establishment.id)
      //     .toPromise()
      //     .then(typologies => {
      //         this.typologies = typologies;

      //         return true;
      //     });
    }
  }

  getBlockedIcalTooltip(ical) {
    return 'Bloqué par ' + ical.map(o => o.name).join(', ');
  }

  isClosed(val) {
    return val.closed.value;
  }

  isOpen(val) {
    return !this.isClosed(val);
  }

  isBlockedByIcal(val) {
    return val.closed.ical && val.closed.ical.length > 0;
  }

  isOpenedByIcal(val) {
    return !this.isBlockedByIcal(val);
  }

  isLoading(typology, rate, day) {
    return _.has(
      this.loadingFields,
      ['typology-' + typology, 'rate-' + rate, day, 'closed'].join('.')
    );
  }

  loadCalendar() {
    return this.calendarApi
      .getCalendar(
        [this.establishment.id],
        this.days[0],
        this.days[this.days.length - 1]
      )
      .toPromise()
      .then(calendar => {
        // Load calendar data
        return this.parseCalendar(calendar.calendar);
      });
  }

  parseCalendar(rawCalendar: any) {
    const calendar = {};
    for (const el of rawCalendar) {
      calendar[el.establishment.id] = {};
      for (const typoId in el.calendar) {
        calendar[el.establishment.id][typoId] = {};
        for (const rateId in el.calendar[typoId].calendar) {
          calendar[el.establishment.id][typoId][rateId] = el.calendar[typoId].calendar[rateId].calendar;
        }
      }
    }

    for (const typology of this.typologies) {
      const typologyObject = {
        typology,
        configured: _.has(calendar, [
          this.establishment.id,
          typology.id,
          this.globalRate
        ]),
        rates: _.get(
          calendar,
          [this.establishment.id, typology.id, this.globalRate],
          {}
        )
      };
      const typologyCalendar = _.find(this.calendar, item => {
        return item.typology.id == typology.id;
      });

      if (typologyCalendar) {
        // Merge
        _.merge(typologyCalendar, typologyObject);
      } else {
        // Push
        this.calendar.push(typologyObject);
      }
    }

    for (const unset of this.unsetAfterLoading) {
      _.unset(this.loadingFields, unset);
    }
    this.loading = false;

    return true;
  }

  setAuto(typology: number, rate: number, date: string, type: string) {
    const data: CalendarDay = {
      date
    };

    data[type] = null;
    _.set(
      this.loadingFields,
      ['typology-' + typology, 'rate-' + rate, date, type],
      true
    );

    this.calendarApi
      .setCalendar(typology, rate, data)
      .toPromise()
      .then(() => {
        this.unsetAfterLoading.push([
          'typology-' + typology,
          'rate-' + rate,
          date,
          type
        ]);
        return this.loadCalendar();
      })
      .catch(e => {});
  }

  configureCalendar(typologyId: number) {
    this.formStepService.resetForm();
    this.formStepService.firstStep = 'calendar';
    this.formStepService.returnUrl = null;
    this.router.navigateByUrl(
      '/calendar/establishments/'
                + this.establishment.id
                + '/typologies/'
                + typologyId
    );
  }

  updateCalendar(
    typology: number,
    rate: number,
    date: string,
    type: string,
    objOldValue: any,
    value: any
  ) {
    const data: CalendarDay = {
      date
    };

    const oldValue = objOldValue.value;

    if (isNaN(value)) {
      objOldValue.value = 0;
      setTimeout(() => {
        objOldValue.value = oldValue;
      }, 0);
      return;
    }

    let newValue = value;

    switch (type) {
      case 'price':
        newValue = Math.round(value * 100) / 100;
        if (newValue != value) {
          objOldValue.value = 0;
          setTimeout(() => {
            objOldValue.value = newValue;
          }, 0);
        }
        break;
      case 'minimumStay':
        if (value <= 0) {
          objOldValue.value = 0;
          setTimeout(() => {
            objOldValue.value = oldValue;
          }, 0);
          return;
        }
        newValue = value;
        break;
    }

    // Set the new value
    objOldValue.value = newValue;

    // Set it in calendar data to send
    data[type] = newValue;

    _.set(
      this.loadingFields,
      ['typology-' + typology, 'rate-' + rate, date, type],
      true
    );

    this.calendarApi
      .setCalendar(typology, rate, data)
      .toPromise()
      .then(() => {
        this.unsetAfterLoading.push([
          'typology-' + typology,
          'rate-' + rate,
          date,
          type
        ]);
        return this.loadCalendar();
      })
      .catch(e => {
        _.unset(this.loadingFields, [
          'typology-' + typology,
          'rate-' + rate,
          date,
          type
        ]);
        objOldValue.value = 0;
        setTimeout(() => {
          objOldValue.value = oldValue;
        }, 0);
      });
  }

  toggleStatus(typology: number, rate: number, date: string) {
    const typo = _.find(this.calendar, item => {
      return item.typology.id == typology;
    });
    if (typo.typology.hasExternalConnection) return;

    const data: CalendarDay = {
      date,
      closed: !typo.rates[date].closed.value
    };

    _.set(
      this.loadingFields,
      ['typology-' + typology, 'rate-' + rate, date, 'closed'],
      true
    );

    this.calendarApi
      .setCalendar(typology, rate, data)
      .toPromise()
      .then(() => {
        typo.rates[date].closed.value = !typo.rates[date].closed.value;
        _.unset(this.loadingFields, [
          'typology-' + typology,
          'rate-' + rate,
          date,
          'closed'
        ]);
        return this.loadCalendar();
      });
  }

  rangeModal(typology: number, rate: number, type: string) {
    const modalRef = this.modalService.open(EditRangeModalContent, {
      centered: true
    });
    modalRef.componentInstance.typologyId = typology;
    modalRef.componentInstance.rateId = rate;
    modalRef.componentInstance.type = type;

    return modalRef.result
      .then(() => {
        return this.loadCalendar();
      })
      .catch(() => {});
  }
}
