import { Component, OnInit } from '@angular/core';
// import { DataLayerService } from '../../services/data-layer.service';
import { Observable, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  startWith, debounceTime, switchMap, map, filter, distinctUntilChanged
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { SharedAnimations } from '../../animations/shared-animations';
import { SearchService } from '../../services/search.service';
import { SiteService } from '../../services/site.service';
import { ApiUrlService } from '../../services/api-url.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [SharedAnimations]
})
export class SearchComponent implements OnInit {
  public paginate = {
    limit: 10,
    page: 1,
    totalItems: 1
  };

  public loading: boolean = true;

  public sites = [];

  public searchMode: boolean = false;

  searchCtrl: FormControl = new FormControl('');

  constructor(
    private siteService: SiteService,
    public searchService: SearchService,
    public apiUrl: ApiUrlService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loadAvailableSites();

    this.searchCtrl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(val => {
      this.searchMode = this.searchCtrl.value > 0;
      this.loadAvailableSites();
    });
  }

  loadAvailableSites() {
    this.siteService.updateAvailableSites(this.paginate.page, this.paginate.limit, this.searchCtrl.value)
      .then(rep => {
        this.sites = rep.sites;
        this.paginate.totalItems = rep.totalItems;
        this.loading = false;
      });
  }

  changePage(page) {
    this.paginate.page = page;

    this.loadAvailableSites();
  }

  async selectSite(event, item) {
    await this.siteService.selectSite(item.id);

    // Redirect to the targeted route if there is one
    if (this.searchService.targetUrl !== null) {
      this.router.navigate([this.searchService.targetUrl], {});
    } else {
      this.router.navigate(['/'], {});
    }

    this.searchService.close();
  }
}
