import { PhototagsCategory } from '../models/phototags-category';

export class PhototagsDb {
  public static listPhotoTags: PhototagsCategory = {
    slug: 'tags',
    subcategories: [
      {
        slug: 'establishment',
        subcategories: [
          {
            slug: 'activities',
            subcategories: [
              {
                slug: 'entertainment',
                tagslist: [
                  {
                    slug: 'water_park'
                  },
                  {
                    slug: 'billiards'
                  },
                  {
                    slug: 'bowling'
                  },
                  {
                    slug: 'casino'
                  },
                  {
                    slug: 'playground_for_children'
                  },
                  {
                    slug: 'darts'
                  },
                  {
                    slug: 'evening_entertainment'
                  },
                  {
                    slug: 'games_room'
                  },
                  {
                    slug: 'karaoke'
                  },
                  {
                    slug: 'kids_club'
                  },
                  {
                    slug: 'discothequedj'
                  },
                  {
                    slug: 'other_entertainment'
                  }
                ]
              },
              {
                slug: 'sports_facilities',
                tagslist: [
                  {
                    slug: 'canoekayak'
                  },
                  {
                    slug: 'bike_ride'
                  },
                  {
                    slug: 'scuba_diving'
                  },
                  {
                    slug: 'fishing'
                  },
                  {
                    slug: 'golf_courses'
                  },
                  {
                    slug: 'walking_tour'
                  },
                  {
                    slug: 'horse_riding'
                  },
                  {
                    slug: 'minigolf'
                  },
                  {
                    slug: 'ski_school'
                  },
                  {
                    slug: 'ski'
                  },
                  {
                    slug: 'snorkeling'
                  },
                  {
                    slug: 'squash'
                  },
                  {
                    slug: 'table_tennis_table'
                  },
                  {
                    slug: 'tennis_court'
                  },
                  {
                    slug: 'windsurfing'
                  },
                  {
                    slug: 'other_sports_facilities'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'other_activities'
              }
            ]
          },
          {
            slug: 'pets_or_pets',
            tagslist: [
              {
                slug: 'pets'
              },
              {
                slug: 'other_pets_or_pets'
              }
            ]
          },
          {
            slug: 'services_and_spaces',
            subcategories: [
              {
                slug: 'business_lounge',
                tagslist: [
                  {
                    slug: 'meetingconference_room'
                  },
                  {
                    slug: 'other_business_space'
                  }
                ]
              },
              {
                slug: 'spa_and_wellness_centre',
                tagslist: [
                  {
                    slug: 'hot_spring_bath'
                  },
                  {
                    slug: 'whirlpool_bath'
                  },
                  {
                    slug: 'massage'
                  },
                  {
                    slug: 'outdoor_bathing'
                  },
                  {
                    slug: 'public_bath'
                  },
                  {
                    slug: 'sauna'
                  },
                  {
                    slug: 'solarium'
                  },
                  {
                    slug: 'hammam'
                  },
                  {
                    slug: 'other_spa_and_wellness_center'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'barbecue'
              },
              {
                slug: 'balconyterrace'
              },
              {
                slug: 'receptionbanquet_hall'
              },
              {
                slug: 'beach'
              },
              {
                slug: 'common_kitchen'
              },
              {
                slug: 'shared_living_roomtv_room'
              },
              {
                slug: 'equipment_for_the_disabled'
              },
              {
                slug: 'gymnasium'
              },
              {
                slug: 'garden'
              },
              {
                slug: 'library'
              },
              {
                slug: 'lobby_or_reception'
              },
              {
                slug: 'barlounge'
              },
              {
                slug: 'shops_in_the_establishment'
              },
              {
                slug: 'terrace_patio'
              },
              {
                slug: 'place_of_worship'
              },
              {
                slug: 'restaurant'
              },
              {
                slug: 'swimming_pool'
              }
            ]
          },
          {
            slug: 'food_and_beverages',
            subcategories: [
              {
                slug: 'beverages',
                tagslist: [
                  {
                    slug: 'alcoholic_beverages'
                  },
                  {
                    slug: 'nonalcoholic_beverages'
                  },
                  {
                    slug: 'other_beverages'
                  }
                ]
              },
              {
                slug: 'food',
                subcategories: [
                  {
                    slug: 'meals',
                    subcategories: [
                      {
                        slug: 'breakfast',
                        tagslist: [
                          {
                            slug:
                                                            'american_breakfast'
                          },
                          {
                            slug: 'asian_breakfast'
                          },
                          {
                            slug: 'breakfast_buffet'
                          },
                          {
                            slug:
                                                            'continental_breakfast'
                          },
                          {
                            slug:
                                                            'englishirish_breakfast'
                          },
                          {
                            slug:
                                                            'italian_breakfast'
                          },
                          {
                            slug: 'other_breakfast'
                          }
                        ]
                      }
                    ],
                    tagslist: [
                      {
                        slug: 'dinner'
                      },
                      {
                        slug: 'lunch'
                      },
                      {
                        slug: 'other_meals'
                      }
                    ]
                  }
                ],
                tagslist: [
                  {
                    slug: 'zoom_on_the_dishes'
                  },
                  {
                    slug: 'other_meals'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'other_food_and_beverages'
              }
            ]
          },
          {
            slug: 'logo_certificate_plate',
            tagslist: [
              {
                slug: 'certificateprice'
              },
              {
                slug: 'logo_or_sign_of_the_establishment'
              },
              {
                slug: 'text'
              },
              {
                slug: 'other_logocertificateplate'
              }
            ]
          },
          {
            slug: 'customers',
            subcategories: [
              {
                slug: 'customers',
                subcategories: [
                  {
                    slug: 'children',
                    tagslist: [
                      {
                        slug: 'teenagers'
                      },
                      {
                        slug: 'infants'
                      },
                      {
                        slug: 'other_children'
                      }
                    ]
                  }
                ],
                tagslist: [
                  {
                    slug: 'group_of_travellers'
                  },
                  {
                    slug: 'other_children'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'family'
              },
              {
                slug: 'staff'
              },
              {
                slug: 'other_customers'
              }
            ]
          },
          {
            slug: 'main_building',
            tagslist: [
              {
                slug: 'aerial_view'
              },
              {
                slug: 'frontentry'
              },
              {
                slug: 'ground_plan'
              },
              {
                slug: 'other_main_building'
              }
            ]
          },
          {
            slug: 'view',
            tagslist: [
              {
                slug: 'city_view'
              },
              {
                slug: 'garden_view'
              },
              {
                slug: 'lake_view'
              },
              {
                slug: 'view_on_site_of_interest'
              },
              {
                slug: 'mountain_view'
              },
              {
                slug: 'pool_view'
              },
              {
                slug: 'river_view'
              },
              {
                slug: 'sea_view'
              },
              {
                slug: 'street_view'
              },
              {
                slug: 'other_view'
              }
            ]
          }
        ],
        tagslist: [
          {
            slug: 'decoration_details'
          },
          {
            slug: 'other'
          }
        ]
      },
      {
        slug: 'hosting',
        subcategories: [
          {
            slug: 'pets_or_pets',
            tagslist: [
              {
                slug: 'pets'
              },
              {
                slug: 'other_pets_or_pets'
              }
            ]
          },
          {
            slug: 'services_and_spaces',
            subcategories: [
              {
                slug: 'spa_and_wellness_centre',
                tagslist: [
                  {
                    slug: 'hot_spring_bath'
                  },
                  {
                    slug: 'whirlpool_bath'
                  },
                  {
                    slug: 'massage'
                  },
                  {
                    slug: 'outdoor_bathing'
                  },
                  {
                    slug: 'public_bath'
                  },
                  {
                    slug: 'sauna'
                  },
                  {
                    slug: 'solarium'
                  },
                  {
                    slug: 'hammam'
                  },
                  {
                    slug: 'other_spa_and_wellness_center'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'barbecue'
              },
              {
                slug: 'balconyterrace'
              },
              {
                slug: 'bathroom'
              },
              {
                slug: 'house'
              },
              {
                slug: 'traykettle_and_coffee_maker'
              },
              {
                slug: 'dining_area'
              },
              {
                slug: 'sports_hallequipment'
              },
              {
                slug: 'garden'
              },
              {
                slug: 'kitchen_or_kitchenette'
              },
              {
                slug: 'library'
              },
              {
                slug: 'salon'
              },
              {
                slug: 'terracepatio'
              },
              {
                slug: 'lounge_or_work_area'
              },
              {
                slug: 'shower'
              },
              {
                slug: 'swimming_pool'
              },
              {
                slug: 'television_and_multimedia'
              },
              {
                slug: 'toilets'
              },
              {
                slug: 'other_services_and_spaces'
              }
            ]
          },
          {
            slug: 'bed',
            tagslist: [
              {
                slug: 'bunk_beds'
              },
              {
                slug: 'crib'
              },
              {
                slug: 'other_bed'
              }
            ]
          },
          {
            slug: 'food_and_beverages',
            subcategories: [
              {
                slug: 'beverages',
                tagslist: [
                  {
                    slug: 'alcoholic_beverages'
                  },
                  {
                    slug: 'nonalcoholic_beverages'
                  },
                  {
                    slug: 'other_beverages'
                  }
                ]
              },
              {
                slug: 'food',
                subcategories: [
                  {
                    slug: 'meals',
                    subcategories: [
                      {
                        slug: 'breakfast',
                        tagslist: [
                          {
                            slug:
                                                            'american_breakfast'
                          },
                          {
                            slug: 'asian_breakfast'
                          },
                          {
                            slug: 'breakfast_buffet'
                          },
                          {
                            slug:
                                                            'continental_breakfast'
                          },
                          {
                            slug:
                                                            'englishirish_breakfast'
                          },
                          {
                            slug:
                                                            'italian_breakfast'
                          },
                          {
                            slug: 'other_breakfast'
                          }
                        ]
                      }
                    ],
                    tagslist: [
                      {
                        slug: 'dinner'
                      },
                      {
                        slug: 'lunch'
                      },
                      {
                        slug: 'other_meals'
                      }
                    ]
                  }
                ],
                tagslist: [
                  {
                    slug: 'zoom_on_the_dishes'
                  },
                  {
                    slug: 'other_meals'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'other_food_and_beverages'
              }
            ]
          },
          {
            slug: 'customers',
            subcategories: [
              {
                slug: 'customers',
                subcategories: [
                  {
                    slug: 'children',
                    tagslist: [
                      {
                        slug: 'teenagers'
                      },
                      {
                        slug: 'infants'
                      },
                      {
                        slug: 'other_children'
                      }
                    ]
                  }
                ],
                tagslist: [
                  {
                    slug: 'group_of_travellers'
                  },
                  {
                    slug: 'other_children'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'family'
              },
              {
                slug: 'staff'
              },
              {
                slug: 'other_customers'
              }
            ]
          },
          {
            slug: 'main_building',
            tagslist: [
              {
                slug: 'aerial_view'
              },
              {
                slug: 'frontentry'
              },
              {
                slug: 'ground_plan'
              },
              {
                slug: 'other_main_building'
              }
            ]
          },
          {
            slug: 'view',
            tagslist: [
              {
                slug: 'city_view'
              },
              {
                slug: 'garden_view'
              },
              {
                slug: 'lake_view'
              },
              {
                slug: 'view_on_site_of_interest'
              },
              {
                slug: 'mountain_view'
              },
              {
                slug: 'pool_view'
              },
              {
                slug: 'river_view'
              },
              {
                slug: 'sea_view'
              },
              {
                slug: 'street_view'
              },
              {
                slug: 'other_view'
              }
            ]
          }
        ],
        tagslist: [
          {
            slug: 'decoration_details'
          },
          {
            slug: 'overview_of_the_room'
          },
          {
            slug: 'other'
          }
        ]
      },
      {
        slug: 'around',
        subcategories: [
          {
            slug: 'activities',
            subcategories: [
              {
                slug: 'entertainment',
                tagslist: [
                  {
                    slug: 'water_park'
                  },
                  {
                    slug: 'billiards'
                  },
                  {
                    slug: 'bowling'
                  },
                  {
                    slug: 'casino'
                  },
                  {
                    slug: 'playground_for_children'
                  },
                  {
                    slug: 'darts'
                  },
                  {
                    slug: 'evening_entertainment'
                  },
                  {
                    slug: 'games_room'
                  },
                  {
                    slug: 'karaoke'
                  },
                  {
                    slug: 'kids_club'
                  },
                  {
                    slug: 'discotheque_dj'
                  },
                  {
                    slug: 'other_entertainment'
                  }
                ]
              },
              {
                slug: 'sports_facilities',
                tagslist: [
                  {
                    slug: 'canoekayak'
                  },
                  {
                    slug: 'bike_ride'
                  },
                  {
                    slug: 'scuba_diving'
                  },
                  {
                    slug: 'fishing'
                  },
                  {
                    slug: 'golf_courses'
                  },
                  {
                    slug: 'walking_tour'
                  },
                  {
                    slug: 'horse_riding'
                  },
                  {
                    slug: 'minigolf'
                  },
                  {
                    slug: 'ski_school'
                  },
                  {
                    slug: 'ski'
                  },
                  {
                    slug: 'snorkeling'
                  },
                  {
                    slug: 'squash'
                  },
                  {
                    slug: 'table_tennis_table'
                  },
                  {
                    slug: 'tennis_court'
                  },
                  {
                    slug: 'windsurfing'
                  },
                  {
                    slug: 'other_sports_facilities'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'other_activities'
              }
            ]
          },
          {
            slug: 'pets_or_pets',
            tagslist: [
              {
                slug: 'pets'
              },
              {
                slug: 'other_pets_or_pets'
              }
            ]
          },
          {
            slug: 'services_and_spaces',
            subcategories: [
              {
                slug: 'spa_and_wellness_centre',
                tagslist: [
                  {
                    slug: 'outdoor_bathing'
                  },
                  {
                    slug: 'public_bath'
                  },
                  {
                    slug: 'other_spa_and_wellness_center'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'beach'
              },
              {
                slug: 'gymnasium'
              },
              {
                slug: 'natural_landscape'
              },
              {
                slug: 'site_of_interest_in_the_surrounding_area'
              },
              {
                slug: 'neighbourhood'
              },
              {
                slug: 'restaurant'
              },
              {
                slug: 'shopping_district'
              },
              {
                slug: 'supermarket_grocery_store'
              },
              {
                slug: 'swimming_pool'
              },
              {
                slug: 'other_services_and_spaces'
              }
            ]
          },
          {
            slug: 'logo_certificate_plate',
            tagslist: [
              {
                slug: 'certificateprice'
              },
              {
                slug: 'logo_or_sign_of_the_establishment'
              },
              {
                slug: 'text'
              },
              {
                slug: 'other_logo_certificate_plate'
              }
            ]
          },
          {
            slug: 'customers',
            subcategories: [
              {
                slug: 'customers',
                subcategories: [
                  {
                    slug: 'children',
                    tagslist: [
                      {
                        slug: 'teenagers'
                      },
                      {
                        slug: 'infants'
                      },
                      {
                        slug: 'other_children'
                      }
                    ]
                  }
                ],
                tagslist: [
                  {
                    slug: 'group_of_travellers'
                  },
                  {
                    slug: 'other_children'
                  }
                ]
              }
            ],
            tagslist: [
              {
                slug: 'family'
              },
              {
                slug: 'staff'
              },
              {
                slug: 'other_customers'
              }
            ]
          }
        ],
        tagslist: [
          {
            slug: 'other'
          }
        ]
      },
      {
        slug: 'background',
        subcategories: [
          {
            slug: 'location',
            tagslist: [
              {
                slug: 'nearby'
              },
              {
                slug: 'on_site'
              },
              {
                slug: 'other_location'
              }
            ]
          },
          {
            slug: 'seasons',
            tagslist: [
              {
                slug: 'autumn'
              },
              {
                slug: 'spring'
              },
              {
                slug: 'summer'
              },
              {
                slug: 'winter'
              },
              {
                slug: 'other_seasons'
              }
            ]
          },
          {
            slug: 'time_of_day',
            tagslist: [
              {
                slug: 'day'
              },
              {
                slug: 'night'
              },
              {
                slug: 'sunrise'
              },
              {
                slug: 'sunset'
              },
              {
                slug: 'other_time_of_day'
              }
            ]
          }
        ]
      }
    ]
  };
}
