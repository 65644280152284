import { OnInit } from '@angular/core';
import { ListingsApiService } from '../listings-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/shared/services/language.service';
var PaymentMethodConfigurationsPropagationComponent = /** @class */ (function () {
    function PaymentMethodConfigurationsPropagationComponent(listingsApi, activeModal, language) {
        this.listingsApi = listingsApi;
        this.activeModal = activeModal;
        this.language = language;
        this.establishments = [];
        this.paymentMethodConfigurations = [];
        this.status = {};
        this.propagating = false;
    }
    PaymentMethodConfigurationsPropagationComponent.prototype.ngOnInit = function () {
        this.resetAllPropagationStatus();
    };
    PaymentMethodConfigurationsPropagationComponent.prototype.resetAllPropagationStatus = function () {
        this.status = {};
        for (var _i = 0, _a = this.establishments; _i < _a.length; _i++) {
            var establishment = _a[_i];
            this.status[establishment.id] = "none";
        }
    };
    PaymentMethodConfigurationsPropagationComponent.prototype.propagate = function () {
        var _this = this;
        this.propagating = true;
        this.resetAllPropagationStatus();
        var _loop_1 = function (establishment) {
            this_1.status[establishment.id] = "propagating";
            this_1.listingsApi.setEstablishmentPaymentMethodConfigurations(establishment.id, this_1.paymentMethodConfigurations).toPromise()
                .then(function (result) {
                console.log('res', result);
                _this.status[establishment.id] = "success";
            });
        };
        var this_1 = this;
        for (var _i = 0, _a = this.establishments; _i < _a.length; _i++) {
            var establishment = _a[_i];
            _loop_1(establishment);
        }
    };
    PaymentMethodConfigurationsPropagationComponent.prototype.close = function () {
        this.activeModal.close();
    };
    return PaymentMethodConfigurationsPropagationComponent;
}());
export { PaymentMethodConfigurationsPropagationComponent };
