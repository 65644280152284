import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config.model';
@Injectable()
export class ConfigService {
  public static settings: Config;

  constructor(private http: HttpClient) {
  }

  load() {
    const jsonFile = 'assets/config/config.json';
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile)
        .toPromise()
        .then((response: Config) => {
          ConfigService.settings = <Config>response;
          resolve();
        })
        .catch((response: any) => {
          reject(new Error(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`));
        });
    });
  }
}
