import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { LocalStoreService } from './shared/services/local-store.service';
import { AuthService } from './shared/services/auth.service';
import { LanguageService } from './shared/services/language.service';
import { SiteService } from './shared/services/site.service';
import { SearchService } from './shared/services/search.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bootDash';

  availableLangs: string[] = ['fr', 'en'];

  constructor(
    private translate: TranslateService,
    private store: LocalStoreService,
    private searchService: SearchService,
    private auth: AuthService,
    private language: LanguageService,
    private site: SiteService,
    private router: Router,
  ) {
    this.init();
  }

  async init() {
    this.language.initLanguages();
    this.translate.addLangs(this.availableLangs);
    this.translate.setDefaultLang('fr');

    this.auth.loadProfile();

    const storedLang = this.store.getItem('lang');
    if (storedLang !== null) {
      this.translate.use(storedLang);
      moment.locale(storedLang);
    } else {
      const browserLang = this.translate.getBrowserLang();
      if (this.availableLangs.indexOf(browserLang) !== -1) {
        this.translate.use(browserLang);
        moment.locale(browserLang);
      } else {
        this.translate.use('en');
        moment.locale('en');
      }
    }

    const siteSelected = this.site.site;
    if (!siteSelected) {
      this.router.navigate(['/dashboard']);
      this.searchService.searchOpen = true;
    }
  }
}
