import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SiteService } from './site.service';
import { SearchService } from './search.service';
import * as i0 from "@angular/core";
import * as i1 from "./site.service";
import * as i2 from "./search.service";
var SiteSelectGuard = /** @class */ (function () {
    function SiteSelectGuard(site, searchService) {
        this.site = site;
        this.searchService = searchService;
    }
    SiteSelectGuard.prototype.canActivate = function (route, state) {
        if (this.site.site) {
            return true;
        }
        // Save the targeted url
        this.searchService.targetUrl = state.url;
        // Select a website
        this.searchService.searchOpen = true;
        console.log('failed!!');
        return false;
    };
    SiteSelectGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SiteSelectGuard_Factory() { return new SiteSelectGuard(i0.ɵɵinject(i1.SiteService), i0.ɵɵinject(i2.SearchService)); }, token: SiteSelectGuard, providedIn: "root" });
    return SiteSelectGuard;
}());
export { SiteSelectGuard };
