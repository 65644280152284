import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import * as ClassicEditor from '../../../external/ckeditor/ckeditor';
import { ApiUrlService } from '../../services/api-url.service';
import { AuthService } from '../../services/auth.service';
import { LanguageService } from '../../services/language.service';
import { SiteService } from '../../services/site.service';

@Component({
  selector: 'i18n-textarea',
  templateUrl: './i18n-textarea.component.html',
  styleUrls: ['./i18n-textarea.component.scss']
})
export class I18nTextareaComponent {
  public Editor = ClassicEditor;

  public editorConfig;

  public langs: string[] = [];

  public selectedLang: string;

  private _value: any;

  public get value(): any {
    return this._value;
  }

  @Input()
  public placeholder: string = '';

  @Input()
  public subtitle: string = '';

  @Input()
  public label: string = '';

  @Input()
  public rows: number = null;

  @Input()
  public cols: number = null;

  @Input()
  public singleLine: boolean = false;

  @Input()
  public emitOnEveryKeypress: boolean = false;

  @Input()
  public isWysiwygEditor: boolean = false;

  @Input()
  public set value(value: any) {
    this._value = { ...value };
    for (const lang of this.langs) {
      if (this._value[lang] == null) {
        this._value[lang] = '';
      }
    }
  }

  @Output() valueChange = new EventEmitter();

  constructor(
    public language: LanguageService,
    private apiUrl: ApiUrlService,
    private auth: AuthService,
    private site: SiteService
  ) {
    this.langs = [...this.language.availableLangs];
    this.selectedLang = this.language.currentLang;
  }

  ngOnInit() {
    this.editorConfig = {
      alignment: {
        options: ['left', 'center', 'right', 'justify']
      },
      simpleUpload: {
        uploadUrl: this.apiUrl.baseUrl() + 'pages/upload',
        headers: {
          Authorization: this.auth.token,
          'X-Environment-ID': this.site.environmentCode
        }
      },
      image: {
        toolbar: [
          'imageStyle:alignLeft',
          'imageStyle:full',
          'imageStyle:alignRight',
          '|',
          'imageTextAlternative'
        ],
        styles: ['full', 'alignLeft', 'alignRight']
      }
    };
  }

  setLang(lang: string) {
    this.selectedLang = lang;
  }

  onChange() {
    this.valueChange.emit(this.value);
  }

  onChangeInput() {
    if (!this.emitOnEveryKeypress) return;
    this.onChange();
  }
}
