import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { translateTemplates } from '@swimlane/ngx-datatable/release/utils';
import { Utils } from '../utils';

export interface IMenuItem {
  id?: string;
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display message for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip message
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
}
export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string; // Display message
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
  disabled?: boolean;
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display message
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false
  };

  constructor(
    public translate: TranslateService
  ) {
  }

  defaultMenu: IMenuItem[] = [
    {
      name: 'listings.LISTINGS',
      type: 'link',
      icon: 'im i-Hotel',
      state: '/listings/establishments'
    },
    {
      name: 'calendar.CALENDAR',
      type: 'link',
      icon: 'fal fa-calendar-alt',
      state: '/calendar'
    },
    {
      name: 'reservations.RESERVATIONS',
      type: 'link',
      icon: 'fal fa-users',
      state: '/reservations'
    },
    {
      name: 'messages.MESSAGES',
      type: 'link',
      icon: 'fal fa-comments',
      state: '/messages'
    },
    {
      name: 'sync.SYNC',
      type: 'link',
      icon: 'fal fa-sync',
      state: '/sync'
    },
    {
      name: 'settings.SETTINGS',
      type: 'dropDown',
      icon: 'fal fa-cog',
      sub: [
        {
          name: 'settings.DESIGN',
          icon: 'fal fa-fw fa-paint-brush',
          state: '/settings/template',
          type: 'link'
        },
        {
          name: 'settings.SITE_SETTINGS',
          icon: 'fal fa-fw fa-cog',
          state: '/settings/site',
          type: 'link'
        },
        {
          name: 'settings.EMAIL_SETTINGS',
          icon: 'fal fa-fw fa-envelope',
          state: '/settings/email',
          type: 'link'
        },
        {
          name: 'settings.ADMINISTRATION',
          icon: 'fal fa-fw fa-shield-alt',
          state: '/settings/administration',
          type: 'link'
        },
        {
          name: 'settings.PAGES',
          icon: 'fal fa-fw fa-browser',
          state: '/settings/pages',
          type: 'link'
        }
      ]
    },
    {
      name: 'administration.ADMINISTRATION',
      type: 'dropDown',
      icon: 'fal fa-unlock-alt',
      sub: [
        {
          icon: 'fal fa-fw fa-globe',
          name: 'administration.SITES',
          state: '/administration/sites',
          type: 'link'
        },
        // {
        //     icon: 'fal fa-fw fa-users',
        //     name: 'administration.USERS',
        //     state: '/administration/users',
        //     type: 'link'
        // },
        {
          icon: 'fal fa-fw fa-browser',
          name: 'administration.ENVIRONMENT',
          state: '/administration/environment',
          type: 'link'
        }
      ]
    }
    // {
    //     name: 'UI kits',
    //     type: 'dropDown',
    //     icon: 'im i-Library',
    //     sub: [
    //         { icon: 'im i-Bell1', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
    //         { icon: 'im i-Split-Horizontal-2-Window', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
    //         { icon: 'im i-Medal-2', name: 'Badges', state: '/uikits/badges', type: 'link' },
    //         {
    //             icon: 'im i-Arrow-Right-in-Circle',
    //             name: 'Buttons',
    //             type: 'dropDown',
    //             sub: [
    //                 { name: 'Bootstrap Buttons', state: '/uikits/buttons', type: 'link' },
    //                 { name: 'Loding Buttons', state: '/uikits/buttons-loading', type: 'link' }
    //             ]
    //         },
    //         { icon: 'im i-ID-Card', name: 'Cards', state: '/uikits/cards', type: 'link' },
    //         { icon: 'im i-Line-Chart-2', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
    //         { icon: 'im i-Mustache', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
    //         { icon: 'im i-Full-Cart', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
    //         { icon: 'im i-Duplicate-Window', name: 'Modals', state: '/uikits/modals', type: 'link' },
    //         { icon: 'im i-Speach-Bubble-2', name: 'Popover', state: '/uikits/popover', type: 'link' },
    //         { icon: 'im i-Like', name: 'Rating', state: '/uikits/rating', type: 'link' },
    //         { icon: 'im i-Loading-3', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
    //     ]
    // },
    // {
    //     name: 'Apps',
    //     type: 'dropDown',
    //     icon: 'im i-Computer-Secure',
    //     sub: [
    //         { icon: 'im i-Add-File', name: 'Invoice Builder', state: '/invoice', type: 'link' },
    //         { icon: 'im i-Email', name: 'Inbox', state: '/inbox', type: 'link' },
    //         { icon: 'im i-Speach-Bubble-3', name: 'Chat', state: '/chat', type: 'link' },
    //         { icon: 'im i-Calendar', name: 'Calendar', state: '/calendar', type: 'link' },
    //     ]
    // },
    // {
    //     name: 'Forms',
    //     type: 'dropDown',
    //     icon: 'im i-File-Clipboard-File--Text',
    //     sub: [
    //         { icon: 'im i-File-Clipboard-Text--Image', name: 'Basic components', state: '/forms/basic', type: 'link' },
    //         { icon: 'im i-Receipt-4', name: 'Input Group', state: '/forms/input-group', type: 'link' },
    //         { icon: 'im i-Face-Style-6', name: 'Input Mask', state: '/forms/input-mask', type: 'link' },
    //         { icon: 'im i-Tag-2', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
    //         { icon: 'im i-Width-Window', name: 'Wizard', state: '/forms/wizard', type: 'link' },
    //         { icon: 'im i-Crop-2', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
    //     ]
    // },
    // {
    //     name: 'Data Tables',
    //     type: 'dropDown',
    //     icon: 'im i-File-Horizontal-Text',
    //     sub: [
    //         { icon: 'im i-File-Horizontal-Text', name: 'List', state: '/tables/list', type: 'link' },
    //         { icon: 'im i-Full-View-Window', name: 'Fullscreen', state: '/tables/full', type: 'link' },
    //         { icon: 'im i-Code-Window', name: 'Paging', state: '/tables/paging', type: 'link' },
    //         { icon: 'im i-Filter-2', name: 'Filter', state: '/tables/filter', type: 'link' },
    //     ]
    // },
    // {
    //     name: 'Sessions',
    //     type: 'dropDown',
    //     icon: 'im i-Administrator',
    //     sub: [
    //         { icon: 'im i-Add-User', name: 'Sign up', state: '/sessions/signup', type: 'link' },
    //         { icon: 'im i-Checked-User', name: 'Sign in', state: '/sessions/signin', type: 'link' },
    //         { icon: 'im i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
    //     ]
    // },
    // {
    //     name: 'Others',
    //     type: 'dropDown',
    //     icon: 'im i-Double-Tap',
    //     sub: [
    //         { icon: 'im i-Error-404-Window', name: 'Not found', state: '/others/404', type: 'link' }
    //     ]
    // },
    // {
    //     name: 'Doc',
    //     type: 'extLink',
    //     tooltip: 'Documentation',
    //     icon: 'im i-Safe-Box1',
    //     state: 'http://demos.ui-lib.com/gull-doc'
    // }
  ];


  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);

  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // populateIDs(items: any[], parentId?) {
  //     items.forEach(item => {
  //         let id = Utils.genId();
  //         item.id = id;
  //         if(parentId) {
  //             item.parentId = parentId;
  //         }
  //         if(item.sub) {
  //             this.populateIDs(item.sub, id);
  //         }
  //     })
  // }

  // You can customize this method to supply different menu for
  // different user type.
  // publishNavigationChange(menuType: string) {
  //   switch (userType) {
  //     case 'admin':
  //       this.menuItems.next(this.adminMenu);
  //       break;
  //     case 'user':
  //       this.menuItems.next(this.userMenu);
  //       break;
  //     default:
  //       this.menuItems.next(this.defaultMenu);
  //   }
  // }
}
