import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigService } from '../services/config.service';
import { Config } from '../models/config.model';
import { SiteService } from './site.service';
import { LocalStoreService } from './local-store.service';
import { Step } from '../models/step.model';
import { CreationFormData } from '../models/creation-form-data.model';
@Injectable()
export class FormStepService {
  steps: Step[] = [
    {
      name: 'site',
      label: 'Site de réservation',
      icon: 'fa-globe'
    },
    {
      name: 'establishment',
      label: 'listings.establishments.ESTABLISHMENT',
      icon: 'fa-hotel'
    },
    {
      name: 'typology',
      label: 'listings.typologies.TYPOLOGY',
      icon: 'fa-bed'
    },
    {
      name: 'calendar',
      label: 'calendar.CALENDAR',
      icon: 'fa-calendar-alt'
    }
  ];

  currentStepPosition: number;

  firstStepPosition: number;

  creationFormData: CreationFormData = {};

  public returnUrl: string = null;

  constructor(private store: LocalStoreService,
    private router: Router,
    private location: Location) {
    this.loadFromStorage();
  }

  loadFromStorage() {
    this.creationFormData = this.store.getItem('creationFormData');
    if (this.creationFormData == null) {
      this.creationFormData = {};
    }
  }

  saveToStorage() {
    this.store.setItem('creationFormData', this.creationFormData);
  }

  resetForm() {
    this.store.setItem('creationFormData', null);
  }

  get firstStep(): string {
    return this.steps[this.store.getItem('creationFormFirstStep')].name;
  }

  set firstStep(stepName: string) {
    const stepIndex = this.steps.findIndex(step => step.name == stepName);
    if (stepIndex === -1) {
      throw new Error('Step ' + stepName + ' not found');
    }
    this.store.setItem('creationFormFirstStep', stepIndex);
  }

  initFormStep(stepName: string) {
    this.loadFromStorage();
    // Load first step
    const savedFirstStep = this.store.getItem('creationFormFirstStep');
    if (savedFirstStep !== null) {
      this.firstStepPosition = savedFirstStep;
    }

    this.steps.forEach((step, index) => {
      if (step.name !== stepName) {
        return;
      }
      if (savedFirstStep === null) {
        this.firstStepPosition = index;
        this.store.setItem('creationFormFirstStep', index);
      }
      this.currentStepPosition = index;
    });
  }
}
