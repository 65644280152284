import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  constructor(private http: HttpClient,
    private apiUrl: ApiUrlService) {

  }

  /**
     * Returns sync information for the establishment view
     * For each typology, returns all associated origin posts
     */
  getEstablishmentView() {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/establishment_view')
      .toPromise();
  }

  /**
     * Returns sync information for the account view
     * For each origin account, returns all associated origin posts and their status
     */
  getAccountView() {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/account_view')
      .toPromise();
  }

  /**
     * Returns the list of establishment and typologies for each of them
     */
  getEstablishmentAndTypologies() {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/establishments')
      .toPromise();
  }

  /**
     * Returns a list of origin accounts
     */
  getOriginAccounts() {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/accounts')
      .toPromise();
  }

  /**
     * Returns a list of origin posts for an account
     * @param accountId The origin account ID
     */
  getAccountPosts(accountId: number) {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts')
      .toPromise();
  }

  /**
     * Fetches and updates origin posts for an account
     * @param accountId The origin account ID
     */
  refreshPosts(accountId: number) {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/update_posts')
      .toPromise();
  }

  /**
     * Creates a new post on the given account, from a given typology
     * @param accountId The origin account ID
     * @param typologyId The typology ID
     */
  createPost(accountId: number, typologyId: number) {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/create_post?typology=' + typologyId)
      .toPromise();
  }

  /**
     * Synchronizes an origin post on the online listing
     * It uploads information from the typology to the listing
     * @param accountId The origin account ID
     * @param postId The origin post ID
     */
  syncAccountPost(accountId: number, postId: number) {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/sync_post')
      .toPromise();
  }

  /**
     * Deletes an origin post on the provider side
     * @param accountId The origin account ID
     * @param postId The origin post ID
     */
  deletePost(accountId: number, postId: number) {
    return this.http.delete<any[]>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId)
      .toPromise();
  }

  /**
     * Fetches and returns regulations information of the given post
     * @param accountId
     * @param postId The origin post ID
     */
  getRegulations(accountId: number, postId: number) {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/regulations')
      .toPromise();
  }

  /**
     * Updates regulation information of the given post
     * @param accountId The origin account ID
     * @param postId The origin post ID
     * @param regulations The regulations required by the provider
     */
  updateRegulations(accountId: number, postId: number, regulations: any) {
    return this.http.post<any[]>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/regulations', {
      regulations
    })
      .toPromise();
  }

  /**
     * Publishes an origin post online
     * @param accountId The origin account ID
     * @param postId The origin post ID
     */
  publishAccountPost(accountId: number, postId: number) {
    return this.http.get<any[]>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/publish_post')
      .toPromise();
  }

  /**
     * Enables synchronization of a post and links to the given typology
     * @param accountId The origin account ID
     * @param postId The origin post ID
     * @param typologyId The typology ID
     */
  linkPostToTypology(accountId: number, postId: number, typologyId: number) {
    return this.http.put<any>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/link_typology', { typologyId })
      .toPromise();
  }

  /**
     * Disables synchronization of a post and unlinks the given typology
     * @param accountId The origin account ID
     * @param postId The origin post ID
     * @param typologyId The typology ID
     */
  unlinkPostTypology(accountId: number, postId: number) {
    return this.http.get<any>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/unlink_typology')
      .toPromise();
  }

  /**
     * Changes a post visibility online
     * @param accountId The origin account ID
     * @param postId The origin post ID
     * @param visible The post visibility
     */
  setPostVisibility(accountId: number, postId: number, visible: boolean) {
    return this.http.post<any>(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/post_visibility', { visible })
      .toPromise();
  }

  /**
     * Opens the Airbnb login page for authentication
     */
  airbnbAuth() {
    this.http.get<{ callback: string }>(this.apiUrl.baseUrl() + 'airbnb/oauth?callback=' + encodeURIComponent(window.location.href))
      .toPromise()
      .then(({ callback }) => {
        window.location.href = callback;
      });
  }
}
