import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Months } from 'src/app/shared/inmemory-db/months';
import { TranslateService } from '@ngx-translate/core';
import { CalendarDay } from 'src/app/shared/models/calendar-day.model';
import { CalendarApiService } from '../../calendar-api.service';

@Component({
  selector: 'app-edit-range',
  templateUrl: './edit-range.component.html',
})
export class EditRangeModalContent implements OnInit {
  submitted: boolean = false;

  loading: boolean = false;

  public daterange: any = {
    start: moment(),
    end: moment()
  };

  public months: string[] = Months.months;

  public days: any[] = [];

  radioGroup: FormGroup;

  valueTextGroup: FormGroup;

  valueRadioGroup: FormGroup;

  public datePickerOptions: any = {
    locale: {
      applyLabel: 'Valider',
      fromLabel: 'De',
      toLabel: 'à',
      format: 'DD/MM/YYYY',
      cancelLabel: 'Annuler',
      firstDay: 1,
      monthNames: moment.months(),
      daysOfWeek: moment.weekdaysMin()
    },
    minDate: moment(),
    ranges: {},
    startDate: this.daterange.start,
    endDate: this.daterange.end
  };

  @Input() typologyId: number;

  @Input() rateId: number;

  @Input() type: string;

  value: string;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private calendarApi: CalendarApiService,
    private translate: TranslateService
  ) {
    // Load date picker ranges
    this.datePickerOptions.ranges['15 prochains jours'] = [moment(), moment().add(15, 'days')];
    this.datePickerOptions.ranges['30 prochains jours'] = [moment(), moment().add(30, 'days')];

    for (let i = 1; i <= 6; i++) {
      const day = moment([moment().year(), moment().month()]).add(i, 'months');
      this.datePickerOptions.ranges[this.translate.instant('common.months.' + this.months[day.month()]) + ' ' + day.year()] = [
        day,
        moment(day).endOf('month')
      ];
    }

    // Init days
    this.days = moment.weekdays().map((item, key) => { return { id: (key == 0) ? 7 : key, day: item, checked: true }; });
    this.days = this.days.slice(moment.localeData().firstDayOfWeek(), 7).concat(this.days.slice(0, moment.localeData().firstDayOfWeek()));
  }

  ngOnInit() {
    this.radioGroup = this.fb.group({
      mode: 'auto'
    });
    this.valueRadioGroup = this.fb.group({
      value: false
    });
    this.valueTextGroup = this.fb.group({
      value: '0'
    });
  }

  displayTextInput() {
    return this.type == 'availability' || this.type == 'price' || this.type == 'minimumStay';
  }

  displayRadioInput() {
    return this.type == 'status' || this.type == 'closedOnArrival';
  }

  getTypeLabel() {
    return this.translate.instant('calendar.' + this.type.toUpperCase());
  }

  selectedDate(value: any, datepicker?: any) {
    // Set a new daterange
    this.daterange = {
      start: value.start,
      end: value.end
    };
  }

  isTextValid() {
    try {
      this.checkValue();
      return true;
    } catch (e) {
      // Value not correct
      return false;
    }
  }

  checkValue() {
    const textValue = this.valueTextGroup.controls.value.value;
    switch (this.type) {
      case 'price':
        if (isNaN(+textValue)) throw new Error();
        return Math.round(+textValue * 100) / 100;
      case 'availability':
        if (isNaN(+textValue)) throw new Error();
        if (+textValue < 0) throw new Error();
        if (!Number.isInteger(+textValue)) throw new Error();
        return +textValue;
      case 'minimumStay':
        if (isNaN(+textValue)) throw new Error();
        if (+textValue < 1) throw new Error();
        if (!Number.isInteger(+textValue)) throw new Error();
        return +textValue;
      case 'status':
      case 'closedOnArrival':
        return this.valueRadioGroup.controls.value.value;
    }
  }

  submit() {
    this.submitted = true;

    let value;


    if (this.displayTextInput()) {
      if (this.radioGroup.controls.mode.value == 'auto') {
        value = null;
      } else {
        try {
          value = this.checkValue();
        } catch (e) {
          // Value not correct
          return;
        }
      }
    } else {
      value = this.valueRadioGroup.controls.value.value;
    }

    // Insert the value
    const data: CalendarDay = {
      startDate: this.daterange.start.format('YYYY-MM-DD'),
      endDate: this.daterange.end.format('YYYY-MM-DD')
    };

    // Set it in calendar data to send
    data[this.type] = value;

    // Set checked days
    data.days = this.days.filter((day) => { return day.checked; })
      .map((day) => { return day.id; });

    this.loading = true;

    this.calendarApi.setCalendar(this.typologyId, this.rateId, data)
      .toPromise()
      .then(() => {
        this.loading = false;
        this.modal.close();
      });
  }
}
