import * as i0 from "@angular/core";
var LocalStoreService = /** @class */ (function () {
    function LocalStoreService() {
        this.ls = window.localStorage;
    }
    LocalStoreService.prototype.setItem = function (key, value) {
        value = JSON.stringify(value);
        this.ls.setItem(key, value);
        return true;
    };
    LocalStoreService.prototype.getItem = function (key) {
        var value = this.ls.getItem(key);
        try {
            return JSON.parse(value);
        }
        catch (e) {
            // console.log(e)
            return null;
        }
    };
    LocalStoreService.prototype.clear = function () {
        this.ls.clear();
    };
    LocalStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStoreService_Factory() { return new LocalStoreService(); }, token: LocalStoreService, providedIn: "root" });
    return LocalStoreService;
}());
export { LocalStoreService };
