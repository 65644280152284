import * as i0 from "@angular/core";
var SearchService = /** @class */ (function () {
    function SearchService() {
        this.searchOpen = false;
        this.targetUrl = null;
    }
    SearchService.prototype.close = function () {
        this.searchOpen = false;
        this.targetUrl = null;
    };
    SearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchService_Factory() { return new SearchService(); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
