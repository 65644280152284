<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'sync.REGULATION_TITLE' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="loading" class="text-center m-4">
        <app-custom-loader></app-custom-loader>
        <h4 class="mt-3">{{ 'common.LOADING' | translate }}</h4>
    </div>
    <form class="creation-form" *ngIf="!loading">
        <div *ngFor="let regulation of regulations.listing_permits" class="mb-4">
            <h4>{{ getRegulationBodyName(regulation.regulatory_body) }}</h4>

            <div *ngIf="regulation.status === 'pending'">
                {{ 'sync.REGULATION_PENDING' | translate }}
            </div>
            <div *ngIf="regulation.status === 'success'">
                {{ 'sync.REGULATION_REGISTERED' | translate }}
            </div>
            <div *ngIf="displayRegulationForm(regulation.status) && regulationHasForm(regulation.regulatory_body, 'registration') && regulationHasForm(regulation.regulatory_body, 'exemption')"
                class="mt-4 mb-2">
                <label class="switch switch-primary mr-3">
                    <span>{{ 'sync.REGULATION_NO_FILL' | translate }}</span>
                    <input type="checkbox" [checked]="isExempted[regulation.regulatory_body]"
                        (change)="toggleIsExempted(regulation.regulatory_body)">
                    <span class="slider"></span>
                </label>
            </div>


            <div
                *ngIf="displayRegulationForm(regulation.status) && regulationHasForm(regulation.regulatory_body, 'registration') && selectedForm[regulation.regulatory_body] === 'registration'">
                <h5>Registration</h5>
            </div>

            <div
                *ngIf="displayRegulationForm(regulation.status) && regulationHasForm(regulation.regulatory_body, 'exemption') && selectedForm[regulation.regulatory_body] === 'exemption'">
                <h5>Exemption</h5>
            </div>

            <div
                *ngIf="displayRegulationForm(regulation.status) && regulationHasForm(regulation.regulatory_body, 'categorization')">
                <div class="form-group">
                    <label for="residency_category">Catégorie de résidence</label>
                    <select class="form-control" name="residency_category" id="residency_category"
                        [(ngModel)]="responses[regulation.regulatory_body].residency_category">
                        <option value="primary_residence">Résidence principale</option>
                        <option value="secondary_residence">Résidence secondaire</option>
                        <option value="non_residential">Hôtel ou hébergement non résidentiel</option>
                    </select>
                </div>
            </div>


            <div
                *ngIf="displayRegulationForm(regulation.status) && regulationHasForm(regulation.regulatory_body, 'tax_collection')">
                <div class="form-group">
                    <label for="tot_category">Type de logement</label>
                    <select class="form-control" name="tot_category" id="tot_category"
                        [(ngModel)]="responses[regulation.regulatory_body].tot_category">
                        <option value="furnished_tourism">Meublé de tourisme</option>
                        <option value="tourist_hotel">Hôtel de tourisme</option>
                        <option value="outdoor_accommodations">Terrain de camping, terrain de caravanage, ou tout
                            autre
                            terrain d’hébergement de plein air</option>
                        <option value="holiday_village">Village de vacances</option>
                        <option value="tourist_residence">Résidence de tourisme</option>
                        <option value="bnb">Chambre d’hôtes</option>
                        <option value="carsite_parking_lot">Emplacement dans une aire de camping-cars ou parc de
                            stationnement touristique</option>
                        <option value="marinas">Port de plaisance</option>
                        <option value="palaces">Palace</option>
                    </select>
                </div>
                <div class="form-group"
                    *ngIf="shouldDisplayTotStars(responses[regulation.regulatory_body].tot_category)">
                    <label for="tot_stars">Nombre d'étoiles</label>
                    <select class="form-control" name="tot_stars" id="tot_stars"
                        [(ngModel)]="responses[regulation.regulatory_body].tot_stars">
                        <option value="nil">Non applicable</option>
                        <option value="1">1 étoile</option>
                        <option value="2">2 étoiles</option>
                        <option value="3">3 étoiles</option>
                        <option value="4">4 étoiles</option>
                        <option value="5">5 étoiles</option>
                    </select>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="close()">{{ 'common.CLOSE' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="saveRegulations()"><i class="far fa-circle-notch fa-spin"
            [hidden]="!saveLoading"></i> {{ 'common.SAVE' | translate }}</button>
</div>