import { EventEmitter } from '@angular/core';
var ColorInputComponent = /** @class */ (function () {
    function ColorInputComponent() {
        this.colorValue = '#ffffff';
        this.colorChange = new EventEmitter();
    }
    Object.defineProperty(ColorInputComponent.prototype, "color", {
        get: function () {
            return this.colorValue;
        },
        set: function (val) {
            this.colorValue = val;
            this.colorChange.emit(this.colorValue);
        },
        enumerable: true,
        configurable: true
    });
    return ColorInputComponent;
}());
export { ColorInputComponent };
