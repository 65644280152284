import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/services/api-url.service";
var SyncService = /** @class */ (function () {
    function SyncService(http, apiUrl) {
        this.http = http;
        this.apiUrl = apiUrl;
    }
    /**
       * Returns sync information for the establishment view
       * For each typology, returns all associated origin posts
       */
    SyncService.prototype.getEstablishmentView = function () {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/establishment_view')
            .toPromise();
    };
    /**
       * Returns sync information for the account view
       * For each origin account, returns all associated origin posts and their status
       */
    SyncService.prototype.getAccountView = function () {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/account_view')
            .toPromise();
    };
    /**
       * Returns the list of establishment and typologies for each of them
       */
    SyncService.prototype.getEstablishmentAndTypologies = function () {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/establishments')
            .toPromise();
    };
    /**
       * Returns a list of origin accounts
       */
    SyncService.prototype.getOriginAccounts = function () {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/accounts')
            .toPromise();
    };
    /**
       * Returns a list of origin posts for an account
       * @param accountId The origin account ID
       */
    SyncService.prototype.getAccountPosts = function (accountId) {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts')
            .toPromise();
    };
    /**
       * Fetches and updates origin posts for an account
       * @param accountId The origin account ID
       */
    SyncService.prototype.refreshPosts = function (accountId) {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/update_posts')
            .toPromise();
    };
    /**
       * Creates a new post on the given account, from a given typology
       * @param accountId The origin account ID
       * @param typologyId The typology ID
       */
    SyncService.prototype.createPost = function (accountId, typologyId) {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/create_post?typology=' + typologyId)
            .toPromise();
    };
    /**
       * Synchronizes an origin post on the online listing
       * It uploads information from the typology to the listing
       * @param accountId The origin account ID
       * @param postId The origin post ID
       */
    SyncService.prototype.syncAccountPost = function (accountId, postId) {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/sync_post')
            .toPromise();
    };
    /**
       * Deletes an origin post on the provider side
       * @param accountId The origin account ID
       * @param postId The origin post ID
       */
    SyncService.prototype.deletePost = function (accountId, postId) {
        return this.http.delete(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId)
            .toPromise();
    };
    /**
       * Fetches and returns regulations information of the given post
       * @param accountId
       * @param postId The origin post ID
       */
    SyncService.prototype.getRegulations = function (accountId, postId) {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/regulations')
            .toPromise();
    };
    /**
       * Updates regulation information of the given post
       * @param accountId The origin account ID
       * @param postId The origin post ID
       * @param regulations The regulations required by the provider
       */
    SyncService.prototype.updateRegulations = function (accountId, postId, regulations) {
        return this.http.post(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/regulations', {
            regulations: regulations
        })
            .toPromise();
    };
    /**
       * Publishes an origin post online
       * @param accountId The origin account ID
       * @param postId The origin post ID
       */
    SyncService.prototype.publishAccountPost = function (accountId, postId) {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/publish_post')
            .toPromise();
    };
    /**
       * Enables synchronization of a post and links to the given typology
       * @param accountId The origin account ID
       * @param postId The origin post ID
       * @param typologyId The typology ID
       */
    SyncService.prototype.linkPostToTypology = function (accountId, postId, typologyId) {
        return this.http.put(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/link_typology', { typologyId: typologyId })
            .toPromise();
    };
    /**
       * Disables synchronization of a post and unlinks the given typology
       * @param accountId The origin account ID
       * @param postId The origin post ID
       * @param typologyId The typology ID
       */
    SyncService.prototype.unlinkPostTypology = function (accountId, postId) {
        return this.http.get(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/unlink_typology')
            .toPromise();
    };
    /**
       * Changes a post visibility online
       * @param accountId The origin account ID
       * @param postId The origin post ID
       * @param visible The post visibility
       */
    SyncService.prototype.setPostVisibility = function (accountId, postId, visible) {
        return this.http.post(this.apiUrl.baseUrl() + 'sync/accounts/' + accountId + '/posts/' + postId + '/post_visibility', { visible: visible })
            .toPromise();
    };
    /**
       * Opens the Airbnb login page for authentication
       */
    SyncService.prototype.airbnbAuth = function () {
        this.http.get(this.apiUrl.baseUrl() + 'airbnb/oauth?callback=' + encodeURIComponent(window.location.href))
            .toPromise()
            .then(function (_a) {
            var callback = _a.callback;
            window.location.href = callback;
        });
    };
    SyncService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SyncService_Factory() { return new SyncService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ApiUrlService)); }, token: SyncService, providedIn: "root" });
    return SyncService;
}());
export { SyncService };
