import { Injectable, Input } from '@angular/core';
import { ListingsApiService } from 'src/app/views/listings/listings-api.service';
import * as _ from 'lodash';
import { Typology } from '../../models/typology.model';

@Injectable()
export class RoomsSelectorService {
  numberOfRooms: number = 0;

  numberOfCommonSpaces: number = 0;

  public compositionTypes = ['roomsComposition', 'commonSpacesComposition'];

  public compositions: any = {
    roomsComposition: [],
    commonSpacesComposition: []
  };

  bedTypes: string[] = [];

  displayRoom: boolean[] = [];

  typology: Typology = {};

  constructor(
    private listingsApi: ListingsApiService
  ) {
    this.listingsApi.getAvailableBedTypes().toPromise()
      .then((bedTypes) => {
        this.bedTypes = bedTypes;
      });
  }

  loadTypology(establishmentId: number, typologyId: number) {
    this.listingsApi.getTypology(establishmentId, typologyId).toPromise()
      .then((typology) => {
        this.typology = typology;

        // Get typology bed composition
        return this.listingsApi.getTypologyBedTypes(typology).toPromise();
      })
      .then((roomsComposition) => {
        this.compositions.roomsComposition = roomsComposition.filter(room => !room.isCommonSpace);
        this.compositions.commonSpacesComposition = roomsComposition.filter(room => room.isCommonSpace);

        this.numberOfRooms = this.compositions.roomsComposition.length;
        this.numberOfCommonSpaces = this.compositions.commonSpacesComposition.length;
      });
  }

  updateRoom(room, type, quantity) {
    if (room[type] != quantity) {
      room[type] = quantity;
    }
  }

  numberOfRoomsChanged(newNumberOfRooms: number, compositionType: 'room' | 'commonSpace') {
    const composition = (compositionType == 'room') ? this.compositions.roomsComposition : this.compositions.commonSpacesComposition;
    const numberOf = (compositionType == 'room') ? this.numberOfRooms : this.numberOfCommonSpaces;

    if (newNumberOfRooms == numberOf) {
      return;
    }

    if (newNumberOfRooms < numberOf) {
      // Remove a room
      composition.splice(newNumberOfRooms, numberOf - newNumberOfRooms);
    } else if (newNumberOfRooms > numberOf) {
      // Add a new room
      const room = {
        isCommonSpace: (compositionType != 'room'),
        composition: {}
      };
      // Add some bed types
      for (let i = 0; i < 4 && i < this.bedTypes.length; i++) {
        room.composition[this.bedTypes[i]] = 0;
      }
      composition.push(room);
    }

    if (compositionType == 'room') {
      this.numberOfRooms = newNumberOfRooms;
    } else {
      this.numberOfCommonSpaces = newNumberOfRooms;
    }
  }

  getNonEmptyTypes(room) {
    const newRoom = [];
    for (const type in room) {
      if (room[type] > 0) {
        newRoom[type] = room[type];
      }
    }
    return newRoom;
  }

  saveTypology() {
    return this.listingsApi.setTypologyBedTypes(this.typology, _.concat(this.compositions.roomsComposition, this.compositions.commonSpacesComposition)).toPromise();
  }

  addNewBedType(room: any, type: string) {
    room[type] = 0;
  }

  deleteBedType(room: any, type: string) {
    delete room[type];
  }
}
