/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./auth-layout.component";
var styles_AuthLayoutComponent = [i0.styles];
var RenderType_AuthLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthLayoutComponent, data: {} });
export { RenderType_AuthLayoutComponent as RenderType_AuthLayoutComponent };
export function View_AuthLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "auth-layout-wrap"], ["style", "background-image: url(./assets/images/photo-wide-4.jpg)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "auth-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AuthLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth-layout", [], null, null, null, View_AuthLayoutComponent_0, RenderType_AuthLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.AuthLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthLayoutComponentNgFactory = i1.ɵccf("app-auth-layout", i3.AuthLayoutComponent, View_AuthLayoutComponent_Host_0, {}, {}, []);
export { AuthLayoutComponentNgFactory as AuthLayoutComponentNgFactory };
