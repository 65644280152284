<div class="app-footer">
	<div class="footer-bottom d-flex align-items-center">
		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">
			<img class="logo" src="./assets/images/logo.png" alt="">
			<div class="ml-2">
				<p class="m-0">&copy; {{ getYear() }} Living Parisian</p>
			</div>
		</div>
	</div>
</div>