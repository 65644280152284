import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Typology } from 'src/app/shared/models/typology.model';
import { PaymentMethodConfiguration } from 'src/app/shared/models/payment-method-configuration.model';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
import { EquipmentService } from 'src/app/shared/services/equipment.service';
import { Establishment } from '../../shared/models/establishment.model';
import { Photo } from '../../shared/models/photo';

@Injectable()
export class ListingsApiService {
  /** Last selected tab in establishment edition page */
  public lastEstablishmentTab: 'general' | 'typologies' = 'general';

  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private equipmentService: EquipmentService
  ) {

  }

  getEstablishments(): Observable<Establishment[]> {
    return this.http.get<Establishment[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments');
  }

  getEstablishment(id: number): Observable<Establishment> {
    return this.http.get<Establishment>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + id);
  }

  
  getPaymentMethodConfiguration(paymentMethodConfigurationId: number): Observable<PaymentMethodConfiguration> {
    return this.http.get<PaymentMethodConfiguration>(this.apiUrlService.siteBaseUrl() + 'payment-method-configuration/' + paymentMethodConfigurationId);
  }
  deletePaymentMethodConfiguration(paymentMethodConfigurationId: number): Observable<PaymentMethodConfiguration> {
    return this.http.delete<PaymentMethodConfiguration>(this.apiUrlService.siteBaseUrl() + 'payment-method-configuration/' + paymentMethodConfigurationId);
  }


  getPaymentMethodConfigurations(): Observable<PaymentMethodConfiguration[]> {
    return this.http.get<PaymentMethodConfiguration[]>(this.apiUrlService.siteBaseUrl() + 'payment-method-configurations');
  }
  setPaymentMethodConfiguration(paymentMethodConfiguration: PaymentMethodConfiguration): Observable<void> {
    return this.http.put<void>(this.apiUrlService.siteBaseUrl() + 'payment-method-configuration', 
      { paymentMethodConfiguration }
    );
  }


  createEstablishment(establishment: Establishment): Observable<Establishment> {
    return this.http.post<Establishment>(this.apiUrlService.siteBaseUrl() + 'listings/establishments', {
      name: establishment.name,
      address: establishment.address,
      street: establishment.street,
      apt: establishment.apt,
      city: establishment.city,
      zipcode: establishment.zipcode,
      state: establishment.state,
      countryCode: establishment.countryCode,
      latitude: establishment.latitude,
      longitude: establishment.longitude
    });
  }

  editEstablishment(establishment: Establishment): Observable<any> {
    return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishment.id, {
      name: establishment.name,
      address: establishment.address,
      street: establishment.street,
      apt: establishment.apt,
      city: establishment.city,
      zipcode: establishment.zipcode,
      state: establishment.state,
      countryCode: establishment.countryCode,
      latitude: establishment.latitude,
      longitude: establishment.longitude,
      description: establishment.description
    });
  }

  setEstablishmentPaymentMethodConfigurations(establishmentId: number, paymentMethodConfigurations: PaymentMethodConfiguration[]): Observable<any> {
    return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/payment-method-configurations', {
      paymentMethodConfigurations
    });
  }

  setEstablishmentEquipments(establishmentId: number, equipments: string[]): Observable<any> {
    return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/equipments', {
      equipments
    });
  }

  /**
   * Separate equipments and rules from a list of equipment slugs
   * @param equipments List of equipments to separate
   */
  async separateEquipmentsAndRules(equipments: string[]): Promise<{equipments: string[], rules: string[]}> {
    const equipmentsRules = {
      equipments: [],
      rules: []
    };

    const establishmentEquipments = await this.equipmentService.getEstablishmentEquipments();
    const typologyEquipments = await this.equipmentService.getTypologyEquipments();

    const establishmentRules = await this.equipmentService.getEstablishmentRules();
    const typologyRules = await this.equipmentService.getTypologyRules();

    for (const equipment of establishmentEquipments.concat(typologyEquipments)) {
      if (equipments.find(val => val === equipment.slug)) {
        equipmentsRules.equipments.push(equipment.slug);
      }
    }

    for (const rule of establishmentRules.concat(typologyRules)) {
      if (equipments.find(val => val === rule.slug)) {
        equipmentsRules.rules.push(rule.slug);
      }
    }
    return equipmentsRules;
  }

  saveEstablishment(establishment: Establishment): Promise<any> {
    return this.editEstablishment(establishment).toPromise()
      .then(() => {
      // Save equipments
        return this.setEstablishmentEquipments(establishment.id, establishment.equipments).toPromise().then(() => {
          // Convert obj to array
          let paymentMethodConfigurations = []; 
          for (let paymentMethod in establishment.paymentMethodConfigurations) {
            paymentMethodConfigurations.push(establishment.paymentMethodConfigurations[paymentMethod]);
          }
          return this.setEstablishmentPaymentMethodConfigurations(establishment.id, paymentMethodConfigurations).toPromise();
        })
      });
  }

  deleteEstablishment(id: number): Observable<any> {
    return this.http.delete(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + id);
  }

  // / TYPOLOGY


  getTypologies(establishmentId: number): Observable<Typology[]> {
    return this.http.get<Typology[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies');
  }

  getTypology(establishmentId: number, typologyId: number): Observable<Typology> {
    return this.http.get<Typology>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId);
  }

  deleteTypology(establishmentId: number, typologyId: number): Observable<any> {
    return this.http.delete(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId);
  }

  createTypology(typology: Typology): Observable<Typology> {
    return this.http.post<Typology>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies', {
      name: typology.name,
      type: typology.type,
      description: typology.description
    });
  }

  editTypology(typology: Typology): Observable<any> {
    return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id, {
      name: typology.name,
      type: typology.type,
      capacity: typology.capacity,
      availability: typology.availability,
      description: typology.description,
      minArrivalTime: typology.minArrivalTime,
      maxArrivalTime: typology.maxArrivalTime,
      minDepartureTime: typology.minDepartureTime,
      maxDepartureTime: typology.maxDepartureTime
    });
  }

  getTypologyCustomFields(establishmentId: number, typologyId: number): Promise<any> {
    return this.http.get<any>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId + '/custom-fields')
      .toPromise();
  }

  setTypologyCustomFields(establishmentId: number, typologyId: number, fields: any): Promise<any> {
    return this.http.put<any>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId + '/custom-fields', {
      fields
    })
      .toPromise();
  }

  setTypologyEquipments(typology: Typology, equipments: string[]): Observable<any> {
    return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/equipments', {
      equipments
    });
  }

  saveTypology(typology: Typology): Promise<any> {
    return this.editTypology(typology).toPromise()
      .then(() => {
      // Save equipments
        return this.setTypologyEquipments(typology, typology.equipments).toPromise();
      });
  }

  // / PHOTO
  getPhotos(establishmentId: number, typologyId: number): Observable<Photo[]> {
    if (typologyId != -1) {
      return this.http.get<Photo[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId + '/photos');
    }
    return this.http.get<Photo[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/photos');
  }

  getLinkPhoto(path: string) : string {
    return this.apiUrlService.siteBaseUrl(true) + path;
  }

  getAvailableBedTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.apiUrlService.baseUrl() + 'typologies/bed-types');
  }

  setTypologyBedTypes(typology: Typology, rooms: any[]): Observable<any> {
    return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/rooms', {
      rooms
    });
  }

  getTypologyBedTypes(typology: Typology): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/rooms');
  }

  deletePhoto(id: number): Observable<any> {
    return this.http.delete(this.apiUrlService.siteBaseUrl() + 'photos/' + id);
  }

  getMainPhoto(establishment: number, typology: number) : string {
    if (typology === -1) {
      return this.apiUrlService.siteBaseUrl(true) + 'photos/main/' + establishment;
    }
    return this.apiUrlService.siteBaseUrl(true) + 'photos/main/' + establishment + '/' + typology;
  }

  updateOrderPhoto(list_id : any[]) : Observable<any> {
    return this.http.put(this.apiUrlService.siteBaseUrl() + 'photos/order', { photos: list_id });
  }

  setPhotoTags(photo: number, list_tags : any[]) : Observable<any> {
    return this.http.put(this.apiUrlService.siteBaseUrl() + 'photos/' + photo + '/tags', { tags: list_tags });
  }

  getTypologyFees(typology: Typology): Observable<any> {
    return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/fees');
  }

  setTypologyFees(typology: Typology, fees: any[]) {
    fees = fees.map(fee => {
      fee = { ...fee };
      if (fee.partType === 'monetary') {
        delete fee.percentage;
      }
      if (fee.partType === 'percentage') {
        delete fee.amount;
      }
      delete fee.partType;
      return fee;
    });

    return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/fees', {
      fees
    });
  }

  getFees(): Observable<any> {
    return this.http.get(this.apiUrlService.baseUrl() + 'fees');
  }
}
