import { Component, OnInit } from '@angular/core';
import { LocalStoreService } from '../../services/local-store.service';
import { FormStepService } from '../../services/form-step.service';

@Component({
  selector: 'app-form-step',
  templateUrl: './form-step.component.html',
  styleUrls: ['./form-step.component.scss']
})
export class FormStepComponent implements OnInit {
  constructor(private store: LocalStoreService, public formStepService: FormStepService) { }

  ngOnInit() {
  }
}
