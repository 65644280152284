import { Injectable } from '@angular/core';

@Injectable()
export class LayoutService {
  public mainContentPadding: boolean = true;

  public moduleLoading: boolean = false;

  constructor() {

  }
}
