import { Component, Input, OnInit } from '@angular/core';
import { Equipment } from '../../models/equipment.model';
import { EquipmentService } from '../../services/equipment.service';

@Component({
  selector: 'app-equipments-selector',
  templateUrl: './equipments-selector.component.html',
  styleUrls: ['./equipments-selector.component.scss']
})
export class EquipmentsSelectorComponent implements OnInit {
  equipments: Equipment[];

  @Input() selectedEquipments: string[];

  @Input() type: string;

  constructor(
    private equipmentService: EquipmentService
  ) {
  }

  async ngOnInit() {
    if (this.type == 'establishment') {
      this.equipments = await this.equipmentService.getEstablishmentEquipments();
      // console.log('equipments', this.equipments);
    } else if (this.type == 'typology') {
      this.equipments = await this.equipmentService.getTypologyEquipments();
      // console.log('equipments2', this.equipments);
    }
  }

  toggleEquipment(equipment: Equipment) {
    const equipmentIndex = this.selectedEquipments.indexOf(equipment.slug);
    if (equipmentIndex === -1) {
      this.selectedEquipments.push(equipment.slug);
    } else {
      this.selectedEquipments.splice(equipmentIndex, 1);
    }
  }
}
