<div class="modal-header">
    <h4 class="modal-title">{{ 'common.PROPAGATE_CONFIGURATION' | translate }}</h4>
</div>
<div class="modal-body">
    <div>
        <div>
            {{ 'listings.establishments.PROPAGATE_PAYMENT_MODES_TO_SELECTED_ESTABLISHMENTS' | translate }}
        </div>
        <button type="button" [disabled]="propagating" (click)="propagate()" class="btn btn-sm btn-primary btn-rounded">{{ 'common.PROPAGATE' | translate }}</button>
    </div>
    <div style="padding: 5px 0">
        <div *ngFor="let establishment of establishments">
            <i *ngIf="status[establishment.id] == 'none'" class="far fa-clock"></i>
            <i *ngIf="status[establishment.id] == 'propagating'" class="far fa-circle-notch fa-spin"></i>
            <i *ngIf="status[establishment.id] == 'success'" style="color: #29cc00" class="far fa-check-circle"></i>
            <i *ngIf="status[establishment.id] == 'error'" style="color: #f74813" class="far fa-times-circle"></i>
            &nbsp;
            <span>{{ establishment.name[language.currentLang] }}</span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default btn-primary mr-2" (click)="close()">
        {{ 'common.CLOSE' | translate }}
    </button>
</div>