import {
  Component, Input, OnInit, LOCALE_ID, Inject
} from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { ListingsApiService } from 'src/app/views/listings/listings-api.service';
import { TranslateService } from '@ngx-translate/core';
import { translateTemplates } from '@swimlane/ngx-datatable/release/utils';
import { Typology } from '../../models/typology.model';
import { SiteService } from '../../services/site.service';

@Component({
  selector: 'app-fee-tax-editor',
  templateUrl: 'fee-tax-editor.component.html',
  styleUrls: ['fee-tax-editor.component.scss']
})
export class FeeTaxEditorComponent implements OnInit {
  @Input() typology?: Typology;

  feeTaxTypes: string[] = [];

  fees: any[] = [];

  // Add form
  addFeeFormOpened: boolean = false;

  addSelectedFee: string = '';

  // Edition
  editingFee: any = null;

  editLoading: boolean = false;

  loaded: boolean = false;

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private listingsApi: ListingsApiService,
    private translate: TranslateService,
    public site: SiteService
  ) {

  }

  ngOnInit() {
    Promise.all([
      this.listingsApi.getFees()
        .toPromise()
        .then(fees => {
          this.feeTaxTypes = fees;
        }),
      this.loadTypologyFees()
    ])
      .then(() => {
        this.loaded = true;
      });
  }

  getCurrencySymbol() {
    return getCurrencySymbol(this.site.currency, 'wide', this.localeId);
  }

  loadTypologyFees() {
    return this.listingsApi.getTypologyFees(this.typology)
      .toPromise()
      .then(fees => {
        this.fees = fees.map(fees => {
          fees.partType = (fees.amount !== null) ? 'monetary' : 'percentage';
          fees.amount = fees.amount || 0;
          fees.percentage = fees.percentage || 0;
          return fees;
        });
        this.fees.sort((a, b) => {
          let comp = 0;
          if (a.type === 'included' && b.type === 'excluded') comp = -1;
          else if (a.type === 'excluded' && b.type === 'included') comp = 1;

          if (comp !== 0) return comp;

          return this.translate.instant('listings.fees.' + a.slug.toUpperCase()).localeCompare(
            this.translate.instant('listings.fees.' + b.slug.toUpperCase())
          );
        });
      });
  }

  openFeeForm() {
    this.addFeeFormOpened = true;
  }

  closeFeeForm() {
    this.addFeeFormOpened = false;
  }

  feeExists(slug: string) {
    return this.fees.find(fee => fee.slug === slug) !== undefined;
  }

  addFee() {
    this.fees.push({
      slug: this.addSelectedFee,
      amount: 0,
      percentage: null,
      partType: 'monetary',
      chargeFrequency: 'per_stay',
      chargeGuest: 'all_guests',
      type: 'included'
    });

    this.closeFeeForm();

    this.saveFees()
      .then(() => {
        this.editingFee = this.fees.find(fee => fee.slug == this.addSelectedFee);
        this.addSelectedFee = '';
      });
  }

  editFee(fee: any) {
    this.editingFee = fee;
  }

  removeFee(slug: string) {
    this.fees.splice(this.fees.findIndex(fee => fee.slug === slug), 1);
    this.saveFees();
  }

  saveFees() {
    return this.listingsApi.setTypologyFees(this.typology, this.fees)
      .toPromise()
      .then(() => {
        return this.loadTypologyFees();
      })
      .then(() => {
        this.editingFee = null;
        this.editLoading = false;
      });
  }
}
