import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { tap, map, catchError } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { LoginToken } from '../models/login-token.model';

import { LocalStoreService } from './local-store.service';
import { ApiUrlService } from './api-url.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated = false;

  token = null;

  user = null;

  constructor(
    private store: LocalStoreService,
    private apiUrl: ApiUrlService,
    private router: Router,
    private http: HttpClient
  ) {
    this.loadFromStorage();
    this.authenticated = this.checkAuth();
  }

  loadFromStorage() {
    this.token = this.store.getItem('token');
    this.user = this.store.getItem('user');
  }

  async loadProfile() {
    if (!this.authenticated) return false;
    return this.http.get<any>(this.apiUrl.baseUrl() + 'auth/profile')
      .toPromise()
      .then(user => {
        this.user = user;

        this.store.setItem('user', this.user);
      });
  }

  checkAuth() {
    return this.token !== null && this.user !== null;
  }

  getuser() {
    return of({});
  }

  signin(credentials) {
    return this.http.post(ConfigService.settings.apiBaseUrl + 'auth', credentials).toPromise()
      .then((data: LoginToken) => {
        this.setCredentials(data);
        this.user = data.user;
        this.authenticated = true;
      });
  }

  setCredentials(credentials: LoginToken) {
    this.token = credentials.token;
    this.user = credentials.user;
    this.store.setItem('token', this.token);
    this.store.setItem('user', this.user);
  }

  refreshToken() {
    return this.http.post(ConfigService.settings.apiBaseUrl + 'auth/refresh', {})
      .pipe(map((data: LoginToken) => {
        this.setCredentials(data);
        return data;
      }));
  }

  signout() {
    this.authenticated = false;
    this.store.setItem('token', null);
    this.store.setItem('user', null);
    this.router.navigateByUrl('/sessions/signin');
  }

  setUserLang(lang: string) {
    if (!this.authenticated) return;
    if (this.user.lang === lang) return;
    return this.http.post(this.apiUrl.baseUrl() + 'user/lang', { lang })
      .toPromise()
      .then(() => this.user.lang = lang);
  }
}
