import { InMemoryDbService } from 'angular-in-memory-web-api';
import { ChatDB } from './chat-db';
import { CountryDB } from './countries';
import { InvoiceDB } from './invoices';
import { MailDB } from './mails';
import { ProductDB } from './products';

export class InMemoryDataService implements InMemoryDbService {
  createDb() {
    return {
      products: ProductDB.products,
      invoices: InvoiceDB.invoices,
      mails: MailDB.messages,
      countries: CountryDB.countries,
      contacts: ChatDB.contacts,
      'chat-collections': ChatDB.chatCollection,
      'chat-user': ChatDB.user
    };
  }
}
