import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/shared/services/search.service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import {
  Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd
} from '@angular/router';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { RouteService } from 'src/app/shared/services/route.service';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  animations: [SharedAnimations]
})
export class AdminLayoutComponent implements OnInit {
  constructor(
    public navService: NavigationService,
    public searchService: SearchService,
    public layout: LayoutService,
    public router: Router,
    public route: RouteService
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.layout.moduleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.layout.moduleLoading = false;
      }
    });
  }
}
