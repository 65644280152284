import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Typology } from '../../models/typology.model';

@Component({
  selector: 'app-allowed-time-selector',
  templateUrl: 'allowed-time-selector.component.html',
  styleUrls: ['allowed-time-selector.component.scss']
})
export class AllowedTimeSelectorComponent {
  @Input()
  set typology(typology: Typology) {
    this.startForm.patchValue({
      minArrivalTime: this.stringTimeToObject(typology.minArrivalTime),
      maxArrivalTime: this.stringTimeToObject(typology.maxArrivalTime),
      minDepartureTime: this.stringTimeToObject(typology.minDepartureTime),
      maxDepartureTime: this.stringTimeToObject(typology.maxDepartureTime),
    });
  }

  get typologyTime() {
    return {
      minArrivalTime: this.objectToStringTime(this.startForm.controls.minArrivalTime.value),
      maxArrivalTime: this.objectToStringTime(this.startForm.controls.maxArrivalTime.value),
      minDepartureTime: this.objectToStringTime(this.startForm.controls.minDepartureTime.value),
      maxDepartureTime: this.objectToStringTime(this.startForm.controls.maxDepartureTime.value)
    };
  }

  stringTimeToObject(time: string) {
    if (!time) return {};
    const hoursMinutes = time.split(':');
    if (hoursMinutes.length < 2) return {};
    return {
      hour: parseInt(hoursMinutes[0]),
      minute: parseInt(hoursMinutes[1])
    };
  }

  objectToStringTime(timeObject: any): string {
    if (!timeObject) return null;
    if (timeObject.hour == null || timeObject.minute == null) return null;
    return [
      (timeObject.hour > 9 ? '' : '0') + timeObject.hour,
      (timeObject.minute > 9 ? '' : '0') + timeObject.minute,
      '00'
    ]
      .join(':');
  }

  startForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.startForm = this.fb.group({
      minArrivalTime: [null, []],
      maxArrivalTime: [null, []],
      minDepartureTime: [null, []],
      maxDepartureTime: [null, []]
    });
  }
}
