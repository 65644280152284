import {
  Component, Input, Output, EventEmitter
} from '@angular/core';

@Component({
  selector: 'color-input',
  templateUrl: 'color-input.component.html',
  styleUrls: ['color-input.component.scss']
})
export class ColorInputComponent {
  public colorValue = '#ffffff';

  @Output() colorChange = new EventEmitter();

  @Input()
  get color() {
    return this.colorValue;
  }

  set color(val) {
    this.colorValue = val;
    this.colorChange.emit(this.colorValue);
  }
}
