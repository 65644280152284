import { OnInit, EventEmitter } from '@angular/core';
var NumberSelectorComponent = /** @class */ (function () {
    function NumberSelectorComponent() {
        this.step = 1;
        this.valueChange = new EventEmitter(true);
    }
    Object.defineProperty(NumberSelectorComponent.prototype, "value", {
        get: function () {
            return this.inputValue;
        },
        set: function (val) {
            this.inputValue = val;
        },
        enumerable: true,
        configurable: true
    });
    NumberSelectorComponent.prototype.sub = function () {
        if (this.minValue !== undefined && this.value <= this.minValue) {
            return;
        }
        this.value = +this.value - +this.step;
        this.valueChange.emit(this.inputValue);
    };
    NumberSelectorComponent.prototype.add = function () {
        if (this.maxValue !== undefined && this.value >= this.maxValue) {
            return;
        }
        this.value = +this.value + +this.step;
        this.valueChange.emit(this.inputValue);
    };
    NumberSelectorComponent.prototype.ngOnInit = function () {
        if (this.minValue !== undefined && this.value <= this.minValue) {
            this.value = this.minValue;
        }
        if (this.maxValue !== undefined && this.value >= this.maxValue) {
            this.value = this.maxValue;
        }
    };
    return NumberSelectorComponent;
}());
export { NumberSelectorComponent };
