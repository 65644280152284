import {
  Component, Input, OnInit, LOCALE_ID, Inject
} from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { ListingsApiService } from 'src/app/views/listings/listings-api.service';
import { TranslateService } from '@ngx-translate/core';
import { translateTemplates } from '@swimlane/ngx-datatable/release/utils';
import { ToastrService } from 'ngx-toastr';
import { SiteService } from '../../services/site.service';
import { Establishment } from '../../models/establishment.model';
import { PaymentMethodConfiguration } from '../../models/payment-method-configuration.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeAccountComponent } from 'src/app/views/listings/stripe-account/stripe-account.component';
import { EstablishmentSelectorComponent } from '../establishment-selector/establishment-selector.component';
import { PaymentMethodConfigurationsPropagationComponent } from 'src/app/views/listings/payment-method-configurations-propagation/payment-method-configurations-propagation.component';

@Component({
  selector: 'app-payment-method-configurations-editor',
  templateUrl: 'payment-method-configurations-editor.component.html',
  styleUrls: ['payment-method-configurations-editor.component.scss']
})
export class PaymentMethodConfigurationsEditorComponent implements OnInit {
  @Input() establishment?: Establishment;

  allPaymentMethodConfigurations: {[key: string]: PaymentMethodConfiguration[]} = {};

  selectedPaymentMethods = {
    'paymentCardData': false,
    'stripe': false
  }



  loaded: boolean = false;

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private listingsApi: ListingsApiService,
    private translate: TranslateService,
    private toastr: ToastrService,
    public site: SiteService,
    public modalService: NgbModal
  ) {
  }

  
  ngOnInit() {
    this.loadAllPaymentMethodConfigurations().then(() => {
      for (let paymentMethod in this.establishment.paymentMethodConfigurations) {
        this.selectedPaymentMethods[paymentMethod] = true;
      }
    });
  }

  
  openPropagationModal() {
    const establishmentSelectorModalRef = this.modalService.open(EstablishmentSelectorComponent, { size: 'lg' });
    establishmentSelectorModalRef.componentInstance.excludedEstablishmentIds = [this.establishment.id];
    establishmentSelectorModalRef.componentInstance.multiple = true;
    
    // modalRef.componentInstance.paymentMethodConfigurationId = paymentMethodConfigurationId;

    
    establishmentSelectorModalRef.result.then((esmResult) => {
      if (esmResult) {
        const propagationModalRef = this.modalService.open(PaymentMethodConfigurationsPropagationComponent, { size: 'lg' });
        propagationModalRef.componentInstance.establishments = esmResult.selectedEstablishments;

        // Get paymentMethodConfigurations from the current state of the establishment
        let paymentMethodConfigurations = []; 
        for (let paymentMethod in this.establishment.paymentMethodConfigurations) {
          paymentMethodConfigurations.push(this.establishment.paymentMethodConfigurations[paymentMethod]);
        }

        propagationModalRef.componentInstance.paymentMethodConfigurations = paymentMethodConfigurations;

        propagationModalRef.result.then((pmResult) => {
          if (pmResult == "success") {
            this.toastr.success(
              this.translate.instant('establishments.UPDATED'), 
              this.translate.instant('common.SUCCESS')
            );
          } 
        
        });
      }
    }, (reason) => {
      console.error(reason);
    });
  }

  switchPaymentCardData() {
    if (this.selectedPaymentMethods.paymentCardData) {
      this.selectedPaymentMethods.paymentCardData = false;
      delete this.establishment.paymentMethodConfigurations.paymentCardData;
    } else {
      this.establishment.paymentMethodConfigurations.paymentCardData = { ...this.allPaymentMethodConfigurations.paymentCardData[0] };
      this.selectedPaymentMethods.paymentCardData = true;
    }
  }

  switchStripe() {
    if (this.selectedPaymentMethods.stripe) {
      this.selectedPaymentMethods.stripe = false;
      delete this.establishment.paymentMethodConfigurations.stripe;
    } else {
      this.establishment.paymentMethodConfigurations.stripe = {
        id: null,
        paymentMethod: 'stripe',
        name: '',
        parsedData: {}
      };
      this.selectedPaymentMethods.stripe = true;
    }
  }

  openStripeAccountModal(edit = false) {
    let paymentMethodConfigurationId = null;
    if (edit && this.establishment.paymentMethodConfigurations.stripe.id) {
      paymentMethodConfigurationId = this.establishment.paymentMethodConfigurations.stripe.id;
    }

    const modalRef = this.modalService.open(StripeAccountComponent, { size: 'lg' })
    modalRef.componentInstance.paymentMethodConfigurationId = paymentMethodConfigurationId;
    
    modalRef.result.then((result) => {
      if (result == "refresh") {
        this.loadAllPaymentMethodConfigurations();
      }
    }, (reason) => {
      console.error(reason);
    });
  }


  // Load the payment method configurations of the paymentMethod provided
  loadAllPaymentMethodConfigurations() {
    return Promise.all([
      this.listingsApi.getPaymentMethodConfigurations()
        .toPromise()
        .then(paymentMethodConfigurations => {
          this.allPaymentMethodConfigurations = {};

          for (let paymentMethodConfiguration of paymentMethodConfigurations) {
            if (typeof this.allPaymentMethodConfigurations[paymentMethodConfiguration.paymentMethod] == 'undefined') {
              this.allPaymentMethodConfigurations[paymentMethodConfiguration.paymentMethod] = [];
            }
            this.allPaymentMethodConfigurations[paymentMethodConfiguration.paymentMethod].push(paymentMethodConfiguration);
          }

          // console.log('allPaymentMethodConfigurations', this.allPaymentMethodConfigurations);
        })
    ])
    .then(() => {
      this.loaded = true;
    });
  }

  getCurrencySymbol() {
    return getCurrencySymbol(this.site.currency, 'wide', this.localeId);
  }



}
