import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Typology } from '../../models/typology.model';

@Component({
  selector: 'app-booking-period-selector',
  templateUrl: 'booking-period-selector.component.html',
  styleUrls: ['booking-period-selector.component.scss']
})
export class BookingPeriodSelectorComponent implements OnInit {
  @Input() public formGroup: FormGroup;
    // // Available min periods (in hours)
		public availableMinPeriods = [24, 48, 72, 168, 360, 720, 1440, 2160];

    // Available max period hour
    public availableMinPeriodHoursFromMidnight = [
      0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19
    ];
    
    // // Available max periods (in hours)
		public availableMaxPeriods = [0, 720, 1440, 2160, 2880, 3600, 4320, 5040, 5760, 6480, 7200, 7920, 8760];

    constructor(
      public translate: TranslateService,
      public changeDetectorRef: ChangeDetectorRef
    ) {

    }

    public updateMinBookingPeriodDisplay() {
      let value = this.formGroup.controls.minBookingPeriod.value;
      this.formGroup.controls.minBookingPeriod.setValue(0);
      this.changeDetectorRef.detectChanges();
      this.formGroup.controls.minBookingPeriod.setValue(value);
    }
    

    public getMinPeriodLabel(period: number) {
      let str = "";
      str += period > 23 ? Math.round(period / 24) + " " : "";
      str += this.translate.instant(
        period > 23
          ? period > 24
            ? "calendar.DAYS"
            : "calendar.DAY"
          : "calendar.SAME_DAY"
      );
      return str;
    };

    public getMinPeriodHoursLabel(hourFromMidnight: number) {
      let str = "";
      if (hourFromMidnight == 0) {
        str = this.translate.instant('calendar.MIDNIGHT');
      } else {
        const hour = 24 - hourFromMidnight;
        let hourString = hourFromMidnight.toString();
        if (hourString.length == 1) {
          hourString = '0' + hourString;
        }
        str = this.translate.instant('TIME_24H') ? moment('2000-01-01 ' + hour + ':00').format('HH:mm') : moment('2000-01-01 ' + hour + ':00').format('hh:mm a');
      }
      return str;
    };

    public getMaxPeriodLabel(period: number) {
        let str = "";
        str += period > 0 ? Math.round(period / (24 * 30)) + " " : "";
        str += this.translate.instant(
            period > 0
                ? period > 1
                    ? "calendar.MONTHS"
                    : "calendar.MONTHS"
                : "calendar.ALWAYS_AVAILABLE"
        );
        return str;
    };



  ngOnInit(): void {
    // console.log("dsdd", this.formGroup);
  }
}
