import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth.guard';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { SiteSelectGuard } from './shared/services/site-select.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'listings/establishments',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: './views/sessions/sessions.module#SessionsModule'
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: './views/others/others.module#OthersModule'
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'listings',
        loadChildren: './views/listings/listings.module#ListingsModule',
        canActivate: [SiteSelectGuard]
      },
      {
        path: 'calendar',
        loadChildren: './views/calendar/calendar.module#CalendarAppModule',
        canActivate: [SiteSelectGuard]
      },
      {
        path: 'administration',
        loadChildren: './views/administration/administration.module#AdministrationModule'
      },
      {
        path: 'profile',
        loadChildren: './views/profile/profile.module#ProfileModule'
      },
      {
        path: 'settings',
        loadChildren: './views/settings/settings.module#SettingsModule',
        canActivate: [SiteSelectGuard]
      },
      {
        path: 'uikits',
        loadChildren: './views/ui-kits/ui-kits.module#UiKitsModule'
      },
      {
        path: 'forms',
        loadChildren: './views/forms/forms.module#AppFormsModule'
      },
      {
        path: 'invoice',
        loadChildren: './views/invoice/invoice.module#InvoiceModule'
      },
      {
        path: 'inbox',
        loadChildren: './views/inbox/inbox.module#InboxModule'
      },
      {
        path: 'tables',
        loadChildren: './views/data-tables/data-tables.module#DataTablesModule'
      },
      {
        path: 'reservations',
        loadChildren: './views/reservations/reservations.module#ReservationsModule',
        canActivate: [SiteSelectGuard]
      },
      {
        path: 'messages',
        loadChildren: './views/messages/messages.module#MessagesModule',
        canActivate: [SiteSelectGuard]
      },
      {
        path: 'sync',
        loadChildren: './views/sync/sync.module#SyncModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
