var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../services/config.service';
import { LocalStoreService } from './local-store.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./local-store.service";
var SiteService = /** @class */ (function () {
    function SiteService(http, store) {
        this.http = http;
        this.store = store;
        this.environmentCode = '';
        this.site = null;
        this.availableSites = [];
    }
    SiteService.prototype.updateAvailableSites = function (page, limit, search) {
        if (page === void 0) { page = 1; }
        if (limit === void 0) { limit = 10; }
        if (search === void 0) { search = null; }
        return this.http.post(ConfigService.settings.apiBaseUrl + 'sites', {
            page: page,
            limit: limit,
            search: search
        })
            .toPromise();
    };
    SiteService.prototype.selectSite = function (siteId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // eslint-disable-next-line no-param-reassign
                siteId = siteId || this.store.getItem('site');
                if (siteId === null) {
                    if (this.availableSites.length >= 1) {
                        // eslint-disable-next-line prefer-destructuring
                        this.site = this.availableSites[0];
                        this.store.setItem('site', this.site.id);
                        return [2 /*return*/, true];
                    }
                    // Cannot select automatically a site
                    return [2 /*return*/, false];
                }
                return [2 /*return*/, this.http.get(ConfigService.settings.apiBaseUrl + 'sites/' + siteId + '/settings')
                        .toPromise()
                        .then(function (site) {
                        if (!site)
                            return false;
                        _this.site = site;
                        _this.store.setItem('site', _this.site.id);
                        return true;
                    })];
            });
        });
    };
    Object.defineProperty(SiteService.prototype, "currency", {
        get: function () {
            return this.site.settings.currency;
        },
        enumerable: true,
        configurable: true
    });
    SiteService.prototype.loadEnvironment = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!ConfigService.settings.environment) return [3 /*break*/, 1];
                        this.environmentCode = ConfigService.settings.environment;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.http.post(ConfigService.settings.apiBaseUrl + 'environments/reference', {
                            domain: window.location.hostname
                        })
                            .toPromise()
                            .then(function (rep) {
                            _this.environmentCode = rep.environmentCode;
                        })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SiteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SiteService_Factory() { return new SiteService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStoreService)); }, token: SiteService, providedIn: "root" });
    return SiteService;
}());
export { SiteService };
