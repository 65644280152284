<div class="search-ui" perfectScrollbar [@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
	<div class="search-header">
		<img src="./assets/images/logo.png" alt="" class="logo">
		<button class="btn btn-icon bg-transparent float-right mt-2" (click)="searchService.close()">
			<i class="im i-Close-Window text-22 text-muted"></i>
		</button>
	</div>

	<input type="text" placeholder="{{ 'search.SEARCH_PLACEHOLDER' | translate }}" class="search-input" [formControl]="searchCtrl" autofocus>

	<div class="search-title">
		<span class="text-muted">{{ 'search.WEBSITE_LIST' | translate }}</span>
    </div>
    
    <div *ngIf="loading" class="text-center">
        <app-custom-loader></app-custom-loader>
    </div>

	<div *ngIf="!loading" class="search-results list-horizontal">
		<div *ngIf="sites && sites.length == 0" class="text-center">
			<div class="icon-legend"><i class="far fa-globe"></i></div>
			<div>
				<h3 *ngIf="!searchMode">{{ 'administration.sites.NO_SITE_YET' | translate }}</h3>
				<h3 *ngIf="searchMode">{{ 'administration.sites.NO_SITE_FOUND' | translate }}</h3>
			</div>
			<div class="mt-4" *ngIf="!searchMode">
				<button routerLink="/administration/sites/create" (click)="searchService.close()" class="btn btn-lg btn-primary">{{ 'administration.sites.CREATE_SITE' | translate }}</button>
			</div>
		</div>
		<div *ngFor="let site of sites | paginate: { itemsPerPage: paginate.limit, currentPage: paginate.page, totalItems: paginate.totalItems }; let i = index;" 
			class="list-item col-md-12 p-0"
			[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
			<div (click)="selectSite($event, site)" class="card o-hidden flex-row mb-4 d-flex">
				<div class="list-thumb d-flex">
					<!-- TUMBNAIL -->
					<img [src]="apiUrl.baseUrl(true) + 'sites/' + site.id + '/settings/resources/favicon'" [alt]="site.name">
				</div>
				<div class="flex-grow-1 pl-2 d-flex">
					<div class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
						<!-- OTHER DATA -->
						<div class="item-title">{{ site.name }}</div>
						<a [href]="'https://' + site.url" target="_blank" rel="noopener">
							<p class="m-0 text-muted text-small w-15 w-sm-100 p-2 site-url">{{ site.url }}</p>
						</a>
					</div>
				
				</div>
			</div>
		</div>
	</div>
	<!-- PAGINATION CONTROL -->
	<div class="col-md-12 mt-3" *ngIf="!loading && sites && sites.length > 0">
		<pagination-controls (pageChange)="changePage($event)" previousLabel="" nextLabel=""></pagination-controls>
	</div>
</div>