var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { LocalStoreService } from './local-store.service';
import { ApiUrlService } from './api-url.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-store.service";
import * as i2 from "./api-url.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(store, apiUrl, router, http) {
        this.store = store;
        this.apiUrl = apiUrl;
        this.router = router;
        this.http = http;
        this.authenticated = false;
        this.token = null;
        this.user = null;
        this.loadFromStorage();
        this.authenticated = this.checkAuth();
    }
    AuthService.prototype.loadFromStorage = function () {
        this.token = this.store.getItem('token');
        this.user = this.store.getItem('user');
    };
    AuthService.prototype.loadProfile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.authenticated)
                    return [2 /*return*/, false];
                return [2 /*return*/, this.http.get(this.apiUrl.baseUrl() + 'auth/profile')
                        .toPromise()
                        .then(function (user) {
                        _this.user = user;
                        _this.store.setItem('user', _this.user);
                    })];
            });
        });
    };
    AuthService.prototype.checkAuth = function () {
        return this.token !== null && this.user !== null;
    };
    AuthService.prototype.getuser = function () {
        return of({});
    };
    AuthService.prototype.signin = function (credentials) {
        var _this = this;
        return this.http.post(ConfigService.settings.apiBaseUrl + 'auth', credentials).toPromise()
            .then(function (data) {
            _this.setCredentials(data);
            _this.user = data.user;
            _this.authenticated = true;
        });
    };
    AuthService.prototype.setCredentials = function (credentials) {
        this.token = credentials.token;
        this.user = credentials.user;
        this.store.setItem('token', this.token);
        this.store.setItem('user', this.user);
    };
    AuthService.prototype.refreshToken = function () {
        var _this = this;
        return this.http.post(ConfigService.settings.apiBaseUrl + 'auth/refresh', {})
            .pipe(map(function (data) {
            _this.setCredentials(data);
            return data;
        }));
    };
    AuthService.prototype.signout = function () {
        this.authenticated = false;
        this.store.setItem('token', null);
        this.store.setItem('user', null);
        this.router.navigateByUrl('/sessions/signin');
    };
    AuthService.prototype.setUserLang = function (lang) {
        var _this = this;
        if (!this.authenticated)
            return;
        if (this.user.lang === lang)
            return;
        return this.http.post(this.apiUrl.baseUrl() + 'user/lang', { lang: lang })
            .toPromise()
            .then(function () { return _this.user.lang = lang; });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.LocalStoreService), i0.ɵɵinject(i2.ApiUrlService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
