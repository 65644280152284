import { Component, Input, OnInit } from '@angular/core';
import { Establishment } from 'src/app/shared/models/establishment.model';
import { ListingsApiService } from '../listings-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentMethodConfiguration } from 'src/app/shared/models/payment-method-configuration.model';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-payment-method-configurations-propagation',
  templateUrl: './payment-method-configurations-propagation.component.html',
  styleUrls: ['./payment-method-configurations-propagation.component.scss']
})
export class PaymentMethodConfigurationsPropagationComponent implements OnInit {
  @Input() establishments: Establishment[] = [];
  @Input() paymentMethodConfigurations: PaymentMethodConfiguration[] = [];

  status: { [establishmentId: number]: string } = {};
  propagating: boolean = false;

  constructor(
    private listingsApi: ListingsApiService,
    public activeModal: NgbActiveModal,
    protected language: LanguageService
  ) { }

  ngOnInit() {
    this.resetAllPropagationStatus();
  }

  resetAllPropagationStatus() {
    this.status = {};
    for (let establishment of this.establishments) {
      this.status[establishment.id] = "none";
    }
  }

  propagate() {
    this.propagating = true;
    this.resetAllPropagationStatus();
    for (let establishment of this.establishments) {
      this.status[establishment.id] = "propagating";
      this.listingsApi.setEstablishmentPaymentMethodConfigurations(establishment.id, this.paymentMethodConfigurations).toPromise()
      .then((result) => {
        console.log('res', result);
        this.status[establishment.id] = "success";

      });
    }
  }

  close () {
    this.activeModal.close();
  }
}
