import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import {
  Observable, Subject, of, throwError
} from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ConfigService } from '../services/config.service';
import { SiteService } from './site.service';
import { LocalStoreService } from './local-store.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();

  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private auth: AuthService, private siteService: SiteService, private localStore: LocalStoreService) {}

  refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    }
    this.refreshTokenInProgress = true;
    return this.auth.refreshToken()
      .pipe(tap(() => {
        this.refreshTokenInProgress = false;
        this.tokenRefreshedSource.next();
      }));
  }

  addHeaders(req: HttpRequest<any>) {
    let newReq = req;

    // Auth header
    if (this.auth.token != null) {
      newReq = newReq.clone({ headers: newReq.headers.set('Authorization', this.auth.token) });
    }

    // Environment header
    if (this.siteService.environmentCode.length > 0) {
      newReq = newReq.clone({ headers: newReq.headers.set('X-Environment-ID', this.siteService.environmentCode) });
    }

    // Lang header
    const lang = this.localStore.getItem('lang');
    if (lang) {
      newReq = newReq.clone({ headers: newReq.headers.append('Accept-Language', lang) });
    }

    return newReq;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = this.addHeaders(req);
    return next.handle(request)
      .pipe(catchError(err => {
        if (req.url == ConfigService.settings.apiBaseUrl + 'auth/refresh') {
          this.auth.signout();
          return throwError(err);
        }

        if (err instanceof HttpErrorResponse && err.status === 401) {
          return this.refreshToken()
            .pipe(
              switchMap(val => {
                request = this.addHeaders(request);
                return next.handle(request);
              })
            );
        }
        return throwError(err);
      }));
  }
}
