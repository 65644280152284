import {
  Component, Input, ViewChild, ElementRef, OnInit
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { Establishment } from '../../models/establishment.model';

@Component({
  selector: 'app-address-input',
  templateUrl: 'address-input.component.html',
  styleUrls: ['address-input.component.scss']
})
export class AddressInputComponent implements OnInit {
  addressForm: FormGroup;

  @Input()
  public establishment: Establishment;

  // Search input
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  constructor(private fb: FormBuilder, private mapsAPILoader: MapsAPILoader) {
    this.addressForm = this.fb.group({
      address: ['', []],
      street: ['', [Validators.required]],
      apt: ['', []],
      zipcode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', []],
      country: [{ value: '', disabled: true }, []],
      countryCode: ['', [Validators.required]],
      latitude: [null, []],
      longitude: [null, []]
    });
  }

  ngOnInit() {
    this.addressForm.patchValue(this.establishment);
    this.loadGoogleMapsAutocomplete();
  }

  loadGoogleMapsAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {}
      );

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;

        // Find address components
        const cityComponent = addressComponents.find(
          comp => comp.types.indexOf('locality') !== -1
        );
        const zipcodeComponent = addressComponents.find(
          comp => comp.types.indexOf('postal_code') !== -1
        );
        const stateComponent = addressComponents.find(
          comp => comp.types.indexOf('administrative_area_level_1') !== -1
        );
        const countryComponent = addressComponents.find(
          comp => comp.types.indexOf('country') !== -1
        );

        let city = '';
        let zipcode = '';
        let state = '';
        let country = '';
        let countryCode = '';

        if (cityComponent) {
          city = cityComponent.long_name;
        }

        if (zipcodeComponent) {
          zipcode = zipcodeComponent.long_name;
        }

        if (stateComponent) {
          state = stateComponent.short_name;
        }

        if (countryComponent) {
          country = countryComponent.long_name;
          countryCode = countryComponent.short_name;
        }

        this.addressForm.patchValue({
          street: place.name,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          address: this.searchElementRef.nativeElement.value,
          city,
          zipcode,
          state,
          country,
          countryCode
        });
      });
    });
  }

  updateEstablishmentObject(establishment: Establishment) {
    establishment.address = this.addressForm.value.address;
    establishment.apt = this.addressForm.value.apt;
    establishment.street = this.addressForm.value.street;
    establishment.latitude = this.addressForm.value.latitude;
    establishment.longitude = this.addressForm.value.longitude;
    establishment.city = this.addressForm.value.city;
    establishment.zipcode = this.addressForm.value.zipcode;
    establishment.state = this.addressForm.value.state;
    establishment.countryCode = this.addressForm.value.countryCode;
  }
}
