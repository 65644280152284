import { PipeTransform, Pipe } from '@angular/core';
import { SiteService } from '../services/site.service';
import { LanguageService } from '../services/language.service';

@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
  langToLocale = {
    fr: 'fr-FR',
    en: 'en-US'
  };

  constructor(private site: SiteService, private lang: LanguageService) {

  }

  transform(value: string): string {
    return new Intl.NumberFormat(this.langToLocale[this.lang.currentLang], { style: 'currency', currency: this.site.currency })
      .format(parseFloat(value));
  }
}
