<div class="modal-header">
    <h4 class="modal-title">{{ (multiple ? 'listings.establishments.SELECT_ESTABLISHMENTS' : 'listings.establishments.SELECT_ESTABLISHMENT') | translate }}</h4>
</div>
<div class="modal-body" style="padding: 0px 30px">
    <div class="row">
        <button class="btn btn-link" (click)="selectAll()">{{ 'common.SELECT_ALL' | translate }}</button>
        <button class="btn btn-link" (click)="unselectAll()">{{ 'common.UNSELECT_ALL' | translate }}</button>
    </div>
    <div style="padding: 5px 0">
        <div class="row" *ngFor="let establishment of establishments">
            <label class="checkbox checkbox-primary">
                <input type="checkbox" [(ngModel)]="establishmentCheckboxes[establishment.id]">
                <span>{{ establishment.name[language.currentLang] }}</span>
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default btn-rounded mr-2"
        (click)="close()">{{ 'common.CANCEL' | translate }}</button>
    <button type="button" (click)="confirmSelection()" class="btn btn-primary btn-rounded">{{ 'common.NEXT' | translate }}</button>
</div>