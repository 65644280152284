import { Component, Input, OnInit } from '@angular/core';
import { StripeAccount } from 'src/app/shared/models/stripe-account';
import { ListingsApiService } from '../listings-api.service';
import { PaymentMethodConfiguration } from 'src/app/shared/models/payment-method-configuration.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-stripe-account',
  templateUrl: './stripe-account.component.html',
  styleUrls: ['./stripe-account.component.scss']
})
export class StripeAccountComponent implements OnInit {
  @Input() paymentMethodConfigurationId: number;
  
  loaded: boolean = false;
  paymentMethodConfiguration: PaymentMethodConfiguration = null;

  constructor(
    private listingsApi: ListingsApiService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) { }


  ngOnInit() {
    if (this.paymentMethodConfigurationId) {
      this.loadPaymentMethodConfiguration();
    } else {
      this.loaded = true;
      this.paymentMethodConfiguration = {
        id: null, 
        paymentMethod: 'stripe',
        name: '',
        parsedData: {
          publicKey: '',
          privateKey: ''
        }
      };
    }
  }

  deletePaymentMethodConfiguration(modalTemplate) {
    this.modalService.open(modalTemplate, { ariaLabelledBy: 'modal-basic-title', centered: true })
      .result.then((result) => {
        if (result == 'delete') {
          this.loaded = false;
          
          this.listingsApi.deletePaymentMethodConfiguration(this.paymentMethodConfiguration.id)
            .toPromise()
            .then(response => {
              this.loaded = true;
              this.activeModal.close('refresh');
            }
          );
        }
      }, (reason) => {
        console.error(reason);
      });
  }

  setPaymentMethodConfiguration() {
    this.loaded = false;
    this.listingsApi.setPaymentMethodConfiguration(this.paymentMethodConfiguration)
      .toPromise()
      .then(response => {
        this.loaded = true;
        this.activeModal.close('refresh');
      }
    );
  }

  // Load the payment method configurations of the paymentMethod provided
  loadPaymentMethodConfiguration() {
    Promise.all([
    this.listingsApi.getPaymentMethodConfiguration(this.paymentMethodConfigurationId)
      .toPromise()
      .then(paymentMethodConfiguration => {
        this.paymentMethodConfiguration = paymentMethodConfiguration;
      }),
      // this.loadEstablishmentPaymentMethodConfigurations()
    ])
    .then(() => {
      this.loaded = true;
    });
  }

}
