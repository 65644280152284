import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { translateTemplates } from '@swimlane/ngx-datatable/release/utils';
import * as moment from 'moment';
import { NavigationService } from '../../services/navigation.service';
import { SearchService } from '../../services/search.service';
import { AuthService } from '../../services/auth.service';
import { Site } from '../../models/site.model';
import { SiteService } from '../../services/site.service';
import { LocalStoreService } from '../../services/local-store.service';
import { ApiUrlService } from '../../services/api-url.service';
import { RouteService } from '../../services/route.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  notifications: any[];

  langs: any[];

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    public siteService: SiteService,
    public translate: TranslateService,
    public auth: AuthService,
    private store: LocalStoreService,
    public apiUrl: ApiUrlService
  ) {
    this.notifications = [
      {
        icon: 'im i-Speach-Bubble-6',
        title: 'New message',
        badge: '3',
        text: 'James: Hey! are you busy?',
        time: new Date(),
        status: 'primary',
        link: '/chat'
      }
    ];

    this.langs = [
      {
        code: 'fr',
        name: 'Français'
      },
      {
        code: 'en',
        name: 'English'
      }
    ];
  }

  ngOnInit() {
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    // if(!state.sidenavOpen) {
    //   return state.sidenavOpen = true;
    // }
    if (state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
      // setTimeout(() => {
      //     state.childnavOpen = true;
      // }, 50);
    }
  }

  selectLang(lang) {
    this.translate.use(lang);
    moment.locale(lang);
    this.store.setItem('lang', lang);
    this.auth.setUserLang(lang);
  }

  signout() {
    this.auth.signout();
  }
}
