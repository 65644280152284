import { Component, Input, OnInit } from '@angular/core';
import { RoomsSelectorService } from './rooms-selector.service';

@Component({
  selector: 'app-rooms-selector',
  templateUrl: './rooms-selector.component.html',
  styleUrls: ['./rooms-selector.component.scss']
})
export class RoomsSelectorComponent implements OnInit {
  @Input() establishmentId: number;

  @Input() typologyId: number;

  constructor(
    public roomsSelectorService: RoomsSelectorService
  ) {
  }

  ngOnInit() {
    this.roomsSelectorService.loadTypology(this.establishmentId, this.typologyId);
  }
}
