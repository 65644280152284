import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SyncService } from 'src/app/views/sync/sync.service';

@Component({
  selector: 'airbnb-regulations',
  templateUrl: 'airbnb-regulations.component.html'
})
export class AirbnbRegulationsComponent implements OnInit {
  @Input()
  public accountId: number;

  @Input()
  public postId: number;

  /** Airbnb regulations object */
  public regulations: any = {};

  /** Regulation data */
  public responses: any = {};

  /** Selected object type for each regulatory body */
  public selectedForm: any = {};

  /** Is exempted status for each regulatory body */
  public isExempted: any = {};

  /** First loading */
  public loading: boolean = false;

  /** Save loading */
  public saveLoading: boolean = false;

  /** Cities needing to fill in a regulation */
  public neededRegulations = {
    registration: ['portland', 'tasmania', 'boston', 'japan', 'catalonia', 'andalusia', 'portugal', 'vancouver', 'santa_monica', 'chicago', 'san_francisco'],
    exemption: ['portland', 'tasmania', 'boston', 'japan', 'catalonia', 'andalusia', 'portugal', 'vancouver', 'santa_monica'],
    categorization: ['france'],
    tax_collection: ['france_tax_collection']
  };

  constructor(
    public activeModal: NgbActiveModal,
    public sync: SyncService
  ) {

  }

  async ngOnInit() {
    this.loading = true;

    this.sync.getRegulations(this.accountId, this.postId)
      .then((regulations) => {
        this.regulations = regulations;

        this.updateStateFromRegulations();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  updateStateFromRegulations() {
    if (!this.regulations) {
      this.regulations = {
        listing_permits: []
      };
    }

    for (const regulation of this.regulations.listing_permits) {
      this.isExempted[regulation.regulatory_body] = false;
      // Empty response object
      this.responses[regulation.regulatory_body] = {};

      // Each regulation (city) can have one or multiple available form
      // (eg. Japan has registration or exemption)
      // But only one of them can be filled
      // Select the current form for each regulation
      const availableForms = [];
      for (const form in this.neededRegulations) {
        if (this.regulationHasForm(regulation.regulatory_body, form)) {
          availableForms.push(form);
        }
      }
      if (availableForms.indexOf('registration') !== -1) {
        // Registration form is priority
        this.selectedForm[regulation.regulatory_body] = 'registration';
      } else {
        // Select one of them
        this.selectedForm[regulation.regulatory_body] = availableForms[0];
      }
    }
  }

  /**
     * Toggle between exemption and registration
     */
  toggleIsExempted(regulatory_body: string) {
    this.isExempted[regulatory_body] = !this.isExempted[regulatory_body];
    this.selectedForm[regulatory_body] = this.isExempted[regulatory_body] ? 'exemption' : 'registration';
  }

  regulationHasForm(regulatory_body: string, regulation_form: string) {
    return this.neededRegulations[regulation_form].indexOf(regulatory_body) !== -1;
  }

  getRegulationType(regulatory_body: string) {
    switch (regulatory_body) {
      case 'france':
        return 'categorization';
      case 'france_tax_collection':
        return 'tax_collection';
    }
    return null;
  }

  getRegulationBodyName(regulatory_body: string) {
    switch (regulatory_body) {
      case 'france_tax_collection':
        return 'France - Collecte de la taxe';
    }
    return regulatory_body.replace('_', ' ').replace(/^\w|\s\w/g, l => l.toLocaleUpperCase());
  }

  displayRegulationForm(status: string) {
    return status !== 'success' && status !== 'pending';
  }

  shouldDisplayTotStars(totCategory: string) {
    return [
      'furnished_tourism',
      'tourist_hotel',
      'outdoor_accomodations',
      'holiday_village',
      'tourist_residence'
    ].indexOf(totCategory) !== -1;
  }

  saveRegulations() {
    if (this.saveLoading) return;

    const airbnbRegulations = {};
    let shouldUpdateRegulations = false;

    for (const regulatoryBody in this.responses) {
      const regulation = this.regulations.listing_permits.find(regulation => regulation.regulatory_body === regulatoryBody);

      if (regulation.status === 'success' || regulation.status === 'pending') continue;


      const regulatoryData = this.responses[regulatoryBody];
      const objKeyName = this.selectedForm[regulatoryBody];

      // Hide parameters
      if (objKeyName === 'tax_collection') {
        if (!this.shouldDisplayTotStars(this.responses[regulatoryBody].tot_category)) {
          delete this.responses[regulatoryBody].tot_stars;
        }
      }

      // We need to update regulations
      shouldUpdateRegulations = true;
      airbnbRegulations[regulatoryBody] = {};
      airbnbRegulations[regulatoryBody][objKeyName] = regulatoryData;
    }

    if (shouldUpdateRegulations) {
      this.saveLoading = true;
      this.sync.updateRegulations(this.accountId, this.postId, airbnbRegulations)
        .then(() => {
          this.activeModal.close();
        })
        .finally(() => {
          this.saveLoading = false;
        });
    } else {
      this.activeModal.close();
    }
  }

  close() {
    this.activeModal.close();
  }
}
