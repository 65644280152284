var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SyncService } from 'src/app/views/sync/sync.service';
var AirbnbRegulationsComponent = /** @class */ (function () {
    function AirbnbRegulationsComponent(activeModal, sync) {
        this.activeModal = activeModal;
        this.sync = sync;
        /** Airbnb regulations object */
        this.regulations = {};
        /** Regulation data */
        this.responses = {};
        /** Selected object type for each regulatory body */
        this.selectedForm = {};
        /** Is exempted status for each regulatory body */
        this.isExempted = {};
        /** First loading */
        this.loading = false;
        /** Save loading */
        this.saveLoading = false;
        /** Cities needing to fill in a regulation */
        this.neededRegulations = {
            registration: ['portland', 'tasmania', 'boston', 'japan', 'catalonia', 'andalusia', 'portugal', 'vancouver', 'santa_monica', 'chicago', 'san_francisco'],
            exemption: ['portland', 'tasmania', 'boston', 'japan', 'catalonia', 'andalusia', 'portugal', 'vancouver', 'santa_monica'],
            categorization: ['france'],
            tax_collection: ['france_tax_collection']
        };
    }
    AirbnbRegulationsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.loading = true;
                this.sync.getRegulations(this.accountId, this.postId)
                    .then(function (regulations) {
                    _this.regulations = regulations;
                    _this.updateStateFromRegulations();
                })
                    .finally(function () {
                    _this.loading = false;
                });
                return [2 /*return*/];
            });
        });
    };
    AirbnbRegulationsComponent.prototype.updateStateFromRegulations = function () {
        if (!this.regulations) {
            this.regulations = {
                listing_permits: []
            };
        }
        for (var _i = 0, _a = this.regulations.listing_permits; _i < _a.length; _i++) {
            var regulation = _a[_i];
            this.isExempted[regulation.regulatory_body] = false;
            // Empty response object
            this.responses[regulation.regulatory_body] = {};
            // Each regulation (city) can have one or multiple available form
            // (eg. Japan has registration or exemption)
            // But only one of them can be filled
            // Select the current form for each regulation
            var availableForms = [];
            for (var form in this.neededRegulations) {
                if (this.regulationHasForm(regulation.regulatory_body, form)) {
                    availableForms.push(form);
                }
            }
            if (availableForms.indexOf('registration') !== -1) {
                // Registration form is priority
                this.selectedForm[regulation.regulatory_body] = 'registration';
            }
            else {
                // Select one of them
                this.selectedForm[regulation.regulatory_body] = availableForms[0];
            }
        }
    };
    /**
       * Toggle between exemption and registration
       */
    AirbnbRegulationsComponent.prototype.toggleIsExempted = function (regulatory_body) {
        this.isExempted[regulatory_body] = !this.isExempted[regulatory_body];
        this.selectedForm[regulatory_body] = this.isExempted[regulatory_body] ? 'exemption' : 'registration';
    };
    AirbnbRegulationsComponent.prototype.regulationHasForm = function (regulatory_body, regulation_form) {
        return this.neededRegulations[regulation_form].indexOf(regulatory_body) !== -1;
    };
    AirbnbRegulationsComponent.prototype.getRegulationType = function (regulatory_body) {
        switch (regulatory_body) {
            case 'france':
                return 'categorization';
            case 'france_tax_collection':
                return 'tax_collection';
        }
        return null;
    };
    AirbnbRegulationsComponent.prototype.getRegulationBodyName = function (regulatory_body) {
        switch (regulatory_body) {
            case 'france_tax_collection':
                return 'France - Collecte de la taxe';
        }
        return regulatory_body.replace('_', ' ').replace(/^\w|\s\w/g, function (l) { return l.toLocaleUpperCase(); });
    };
    AirbnbRegulationsComponent.prototype.displayRegulationForm = function (status) {
        return status !== 'success' && status !== 'pending';
    };
    AirbnbRegulationsComponent.prototype.shouldDisplayTotStars = function (totCategory) {
        return [
            'furnished_tourism',
            'tourist_hotel',
            'outdoor_accomodations',
            'holiday_village',
            'tourist_residence'
        ].indexOf(totCategory) !== -1;
    };
    AirbnbRegulationsComponent.prototype.saveRegulations = function () {
        var _this = this;
        if (this.saveLoading)
            return;
        var airbnbRegulations = {};
        var shouldUpdateRegulations = false;
        var _loop_1 = function (regulatoryBody) {
            var regulation = this_1.regulations.listing_permits.find(function (regulation) { return regulation.regulatory_body === regulatoryBody; });
            if (regulation.status === 'success' || regulation.status === 'pending')
                return "continue";
            var regulatoryData = this_1.responses[regulatoryBody];
            var objKeyName = this_1.selectedForm[regulatoryBody];
            // Hide parameters
            if (objKeyName === 'tax_collection') {
                if (!this_1.shouldDisplayTotStars(this_1.responses[regulatoryBody].tot_category)) {
                    delete this_1.responses[regulatoryBody].tot_stars;
                }
            }
            // We need to update regulations
            shouldUpdateRegulations = true;
            airbnbRegulations[regulatoryBody] = {};
            airbnbRegulations[regulatoryBody][objKeyName] = regulatoryData;
        };
        var this_1 = this;
        for (var regulatoryBody in this.responses) {
            _loop_1(regulatoryBody);
        }
        if (shouldUpdateRegulations) {
            this.saveLoading = true;
            this.sync.updateRegulations(this.accountId, this.postId, airbnbRegulations)
                .then(function () {
                _this.activeModal.close();
            })
                .finally(function () {
                _this.saveLoading = false;
            });
        }
        else {
            this.activeModal.close();
        }
    };
    AirbnbRegulationsComponent.prototype.close = function () {
        this.activeModal.close();
    };
    return AirbnbRegulationsComponent;
}());
export { AirbnbRegulationsComponent };
