import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-equipment-icon',
  template: `
  <span>
    <i *ngIf="icon && !icon.startsWith('custom_')" [ngClass]="icon"></i>
    <img *ngIf="icon && icon.startsWith('custom_')" [src]="'/assets/icons/custom/' + iconFile" class="equipment-icon">
  </span>
`,
  styleUrls: ['./equipment-icon.component.scss'],
})
export class EquipmentIconComponent implements OnInit {
  @Input()
  public icon: string;

  public get iconFile() {
    if (!this.icon || this.icon.length === 0) return '';
    return this.icon.replace('custom_', '') + '.png';
  }

  constructor() { }

  ngOnInit() {
  }
}
