import { Component, Input, OnInit } from '@angular/core';
import { Rule } from '../../models/rule.model';
import { EquipmentService } from '../../services/equipment.service';

@Component({
  selector: 'app-rules-selector',
  templateUrl: './rules-selector.component.html',
  styleUrls: ['./rules-selector.component.scss']
})
export class RulesSelectorComponent implements OnInit {
  rules: Rule[];

  @Input() selectedRules: string[];

  @Input() type: string;

  @Input() disabled: boolean;

  constructor(
    private equipmentService: EquipmentService
  ) {
  }

  async ngOnInit() {
    if (this.type == 'establishment') {
      this.rules = await this.equipmentService.getEstablishmentRules();
    } else if (this.type == 'typology') {
      this.rules = await this.equipmentService.getTypologyRules();
    }
  }


  toggleRule(event: any, rule: Rule) {
    event.preventDefault();
    event.stopPropagation();
    const ruleIndex = this.selectedRules.indexOf(rule.slug);
    if (ruleIndex === -1) {
      this.selectedRules.push(rule.slug);
    } else {
      this.selectedRules.splice(ruleIndex, 1);
    }
  }
}
