import { FormBuilder } from '@angular/forms';
var AllowedTimeSelectorComponent = /** @class */ (function () {
    function AllowedTimeSelectorComponent(fb) {
        this.fb = fb;
        this.startForm = this.fb.group({
            minArrivalTime: [null, []],
            maxArrivalTime: [null, []],
            minDepartureTime: [null, []],
            maxDepartureTime: [null, []]
        });
    }
    Object.defineProperty(AllowedTimeSelectorComponent.prototype, "typology", {
        set: function (typology) {
            this.startForm.patchValue({
                minArrivalTime: this.stringTimeToObject(typology.minArrivalTime),
                maxArrivalTime: this.stringTimeToObject(typology.maxArrivalTime),
                minDepartureTime: this.stringTimeToObject(typology.minDepartureTime),
                maxDepartureTime: this.stringTimeToObject(typology.maxDepartureTime),
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AllowedTimeSelectorComponent.prototype, "typologyTime", {
        get: function () {
            return {
                minArrivalTime: this.objectToStringTime(this.startForm.controls.minArrivalTime.value),
                maxArrivalTime: this.objectToStringTime(this.startForm.controls.maxArrivalTime.value),
                minDepartureTime: this.objectToStringTime(this.startForm.controls.minDepartureTime.value),
                maxDepartureTime: this.objectToStringTime(this.startForm.controls.maxDepartureTime.value)
            };
        },
        enumerable: true,
        configurable: true
    });
    AllowedTimeSelectorComponent.prototype.stringTimeToObject = function (time) {
        if (!time)
            return {};
        var hoursMinutes = time.split(':');
        if (hoursMinutes.length < 2)
            return {};
        return {
            hour: parseInt(hoursMinutes[0]),
            minute: parseInt(hoursMinutes[1])
        };
    };
    AllowedTimeSelectorComponent.prototype.objectToStringTime = function (timeObject) {
        if (!timeObject)
            return null;
        if (timeObject.hour == null || timeObject.minute == null)
            return null;
        return [
            (timeObject.hour > 9 ? '' : '0') + timeObject.hour,
            (timeObject.minute > 9 ? '' : '0') + timeObject.minute,
            '00'
        ]
            .join(':');
    };
    return AllowedTimeSelectorComponent;
}());
export { AllowedTimeSelectorComponent };
