import { OnInit } from '@angular/core';
var EquipmentIconComponent = /** @class */ (function () {
    function EquipmentIconComponent() {
    }
    Object.defineProperty(EquipmentIconComponent.prototype, "iconFile", {
        get: function () {
            if (!this.icon || this.icon.length === 0)
                return '';
            return this.icon.replace('custom_', '') + '.png';
        },
        enumerable: true,
        configurable: true
    });
    EquipmentIconComponent.prototype.ngOnInit = function () {
    };
    return EquipmentIconComponent;
}());
export { EquipmentIconComponent };
