import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from '../models/site.model';
import { ConfigService } from '../services/config.service';
import { LocalStoreService } from './local-store.service';


@Injectable({
  providedIn: 'root'
})
/**
 * Site selection
 */
export class SiteService {
  environmentCode: string = '';

  public site: Site = null;

  availableSites: Site[] = [];

  constructor(
    private http: HttpClient,
    private store: LocalStoreService,
  ) {
  }

  updateAvailableSites(page: number = 1, limit: number = 10, search: string = null) {
    return this.http.post<any>(ConfigService.settings.apiBaseUrl + 'sites', {
      page,
      limit,
      search
    })
      .toPromise();
  }

  async selectSite(siteId?: number) {
    // eslint-disable-next-line no-param-reassign
    siteId = siteId || this.store.getItem('site');

    if (siteId === null) {
      if (this.availableSites.length >= 1) {
        // eslint-disable-next-line prefer-destructuring
        this.site = this.availableSites[0];
        this.store.setItem('site', this.site.id);
        return true;
      }

      // Cannot select automatically a site
      return false;
    }

    return this.http.get<any>(ConfigService.settings.apiBaseUrl + 'sites/' + siteId + '/settings')
      .toPromise()
      .then(site => {
        if (!site) return false;
        this.site = site;
        this.store.setItem('site', this.site.id);
        return true;
      });
  }

  get currency(): string {
    return this.site.settings.currency;
  }

  async loadEnvironment(): Promise<void> {
    if (ConfigService.settings.environment) {
      this.environmentCode = ConfigService.settings.environment;
    } else {
      await this.http.post<any>(ConfigService.settings.apiBaseUrl + 'environments/reference', {
        domain: window.location.hostname
      })
        .toPromise()
        .then(rep => {
          this.environmentCode = rep.environmentCode;
        });
    }
  }
}
