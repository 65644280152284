var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter } from '@angular/core';
import * as ClassicEditor from '../../../external/ckeditor/ckeditor';
import { ApiUrlService } from '../../services/api-url.service';
import { AuthService } from '../../services/auth.service';
import { LanguageService } from '../../services/language.service';
import { SiteService } from '../../services/site.service';
var I18nTextareaComponent = /** @class */ (function () {
    function I18nTextareaComponent(language, apiUrl, auth, site) {
        this.language = language;
        this.apiUrl = apiUrl;
        this.auth = auth;
        this.site = site;
        this.Editor = ClassicEditor;
        this.langs = [];
        this.placeholder = '';
        this.subtitle = '';
        this.label = '';
        this.rows = null;
        this.cols = null;
        this.singleLine = false;
        this.emitOnEveryKeypress = false;
        this.isWysiwygEditor = false;
        this.valueChange = new EventEmitter();
        this.langs = this.language.availableLangs.slice();
        this.selectedLang = this.language.currentLang;
    }
    Object.defineProperty(I18nTextareaComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = __assign({}, value);
            for (var _i = 0, _a = this.langs; _i < _a.length; _i++) {
                var lang = _a[_i];
                if (this._value[lang] == null) {
                    this._value[lang] = '';
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    I18nTextareaComponent.prototype.ngOnInit = function () {
        this.editorConfig = {
            alignment: {
                options: ['left', 'center', 'right', 'justify']
            },
            simpleUpload: {
                uploadUrl: this.apiUrl.baseUrl() + 'pages/upload',
                headers: {
                    Authorization: this.auth.token,
                    'X-Environment-ID': this.site.environmentCode
                }
            },
            image: {
                toolbar: [
                    'imageStyle:alignLeft',
                    'imageStyle:full',
                    'imageStyle:alignRight',
                    '|',
                    'imageTextAlternative'
                ],
                styles: ['full', 'alignLeft', 'alignRight']
            }
        };
    };
    I18nTextareaComponent.prototype.setLang = function (lang) {
        this.selectedLang = lang;
    };
    I18nTextareaComponent.prototype.onChange = function () {
        this.valueChange.emit(this.value);
    };
    I18nTextareaComponent.prototype.onChangeInput = function () {
        if (!this.emitOnEveryKeypress)
            return;
        this.onChange();
    };
    return I18nTextareaComponent;
}());
export { I18nTextareaComponent };
