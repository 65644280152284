import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public searchOpen: boolean = false;

  public targetUrl: string = null;

  constructor() { }

  close() {
    this.searchOpen = false;
    this.targetUrl = null;
  }
}
