import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { TagInputModule } from 'ngx-chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { Ng5SliderModule } from 'ng5-slider';
import { ColorPickerModule } from 'ngx-color-picker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxPaginationModule } from 'ngx-pagination';
import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { FeatherIconComponent } from './feather-icon/feather-icon.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './layouts/blank-layout/blank-layout.component';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { FooterComponent } from './footer/footer.component';
import { CustomizerComponent } from './customizer/customizer.component';
import { EquipmentsSelectorComponent } from './equipments-selector/equipments-selector.component';
import { RulesSelectorComponent } from './rules-selector/rules-selector.component';
import { FormStepComponent } from './form-step/form-step.component';
import { NumberSelectorComponent } from './number-selector/number-selector.component';
import { SimpleUploadComponent } from './simple-upload/simple-upload.component';
import { RoomsSelectorComponent } from './rooms-selector/rooms-selector.component';
import { PhotoTagsComponent } from './photo-tags/photo-tags.component';
import { TypologyCalendarComponent } from './typology-calendar/typology-calendar.component';
import { FeeTaxEditorComponent } from './fee-tax-editor/fee-tax-editor.component';
import { PaymentMethodConfigurationsEditorComponent } from './payment-method-configurations-editor/payment-method-configurations-editor.component'
import { EstablishmentSelectorComponent } from './establishment-selector/establishment-selector.component'
import { ColorInputComponent } from './color-input/color-input.component';
import { AlertComponent } from './alert/alert.component';
import { AllowedTimeSelectorComponent } from './allowed-time-selector/allowed-time-selector.component';
import { I18nTextareaComponent } from './i18n-textarea/i18n-textarea.component';
import { AddressInputComponent } from './address-input/address-input.component';
import { AirbnbRegulationsComponent } from './airbnb-regulations/airbnb-regulations.component';
import { CustomLoaderComponent } from './custom-loader/custom-loader.component';
import { BookingPeriodSelectorComponent } from './booking-period-selector/booking-period-selector.component';
import { SearchComponent } from './search/search.component';
import { EquipmentIconComponent } from './equipment-icon/equipment-icon.component';

const components = [
  BtnLoadingComponent,
  FeatherIconComponent,
  HeaderComponent,
  SidebarComponent,
  FooterComponent,
  CustomizerComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BlankLayoutComponent,
  EquipmentsSelectorComponent,
  RulesSelectorComponent,
  FormStepComponent,
  NumberSelectorComponent,
  SimpleUploadComponent,
  RoomsSelectorComponent,
  PhotoTagsComponent,
  TypologyCalendarComponent,
  FeeTaxEditorComponent,
  PaymentMethodConfigurationsEditorComponent,
  EstablishmentSelectorComponent,
  ColorInputComponent,
  AlertComponent,
  AllowedTimeSelectorComponent,
  I18nTextareaComponent,
  AddressInputComponent,
  AirbnbRegulationsComponent,
  SearchComponent,
  CustomLoaderComponent,
  BookingPeriodSelectorComponent,
  EquipmentIconComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedPipesModule,
    SharedDirectivesModule,
    PerfectScrollbarModule,
    NgbModule,
    TranslateModule,
    TagInputModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    NgbPopoverModule,
    Ng5SliderModule,
    ColorPickerModule,
    CKEditorModule,
    NgxPaginationModule,
  ],
  declarations: components,
  entryComponents: [
    AirbnbRegulationsComponent,
    EstablishmentSelectorComponent
  ],
  exports: components
})
export class SharedComponentsModule { }
