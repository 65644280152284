/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./custom-loader.component";
var styles_CustomLoaderComponent = [i0.styles];
var RenderType_CustomLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomLoaderComponent, data: {} });
export { RenderType_CustomLoaderComponent as RenderType_CustomLoaderComponent };
export function View_CustomLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "lds-ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_CustomLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-loader", [], null, null, null, View_CustomLoaderComponent_0, RenderType_CustomLoaderComponent)), i1.ɵdid(1, 49152, null, 0, i2.CustomLoaderComponent, [], null, null)], null, null); }
var CustomLoaderComponentNgFactory = i1.ɵccf("app-custom-loader", i2.CustomLoaderComponent, View_CustomLoaderComponent_Host_0, {}, {}, []);
export { CustomLoaderComponentNgFactory as CustomLoaderComponentNgFactory };
