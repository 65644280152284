var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
import { EquipmentService } from 'src/app/shared/services/equipment.service';
var ListingsApiService = /** @class */ (function () {
    function ListingsApiService(http, apiUrlService, equipmentService) {
        this.http = http;
        this.apiUrlService = apiUrlService;
        this.equipmentService = equipmentService;
        /** Last selected tab in establishment edition page */
        this.lastEstablishmentTab = 'general';
    }
    ListingsApiService.prototype.getEstablishments = function () {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments');
    };
    ListingsApiService.prototype.getEstablishment = function (id) {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + id);
    };
    ListingsApiService.prototype.getPaymentMethodConfiguration = function (paymentMethodConfigurationId) {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'payment-method-configuration/' + paymentMethodConfigurationId);
    };
    ListingsApiService.prototype.deletePaymentMethodConfiguration = function (paymentMethodConfigurationId) {
        return this.http.delete(this.apiUrlService.siteBaseUrl() + 'payment-method-configuration/' + paymentMethodConfigurationId);
    };
    ListingsApiService.prototype.getPaymentMethodConfigurations = function () {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'payment-method-configurations');
    };
    ListingsApiService.prototype.setPaymentMethodConfiguration = function (paymentMethodConfiguration) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'payment-method-configuration', { paymentMethodConfiguration: paymentMethodConfiguration });
    };
    ListingsApiService.prototype.createEstablishment = function (establishment) {
        return this.http.post(this.apiUrlService.siteBaseUrl() + 'listings/establishments', {
            name: establishment.name,
            address: establishment.address,
            street: establishment.street,
            apt: establishment.apt,
            city: establishment.city,
            zipcode: establishment.zipcode,
            state: establishment.state,
            countryCode: establishment.countryCode,
            latitude: establishment.latitude,
            longitude: establishment.longitude
        });
    };
    ListingsApiService.prototype.editEstablishment = function (establishment) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishment.id, {
            name: establishment.name,
            address: establishment.address,
            street: establishment.street,
            apt: establishment.apt,
            city: establishment.city,
            zipcode: establishment.zipcode,
            state: establishment.state,
            countryCode: establishment.countryCode,
            latitude: establishment.latitude,
            longitude: establishment.longitude,
            description: establishment.description
        });
    };
    ListingsApiService.prototype.setEstablishmentPaymentMethodConfigurations = function (establishmentId, paymentMethodConfigurations) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/payment-method-configurations', {
            paymentMethodConfigurations: paymentMethodConfigurations
        });
    };
    ListingsApiService.prototype.setEstablishmentEquipments = function (establishmentId, equipments) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/equipments', {
            equipments: equipments
        });
    };
    /**
     * Separate equipments and rules from a list of equipment slugs
     * @param equipments List of equipments to separate
     */
    ListingsApiService.prototype.separateEquipmentsAndRules = function (equipments) {
        return __awaiter(this, void 0, void 0, function () {
            var equipmentsRules, establishmentEquipments, typologyEquipments, establishmentRules, typologyRules, _loop_1, _i, _a, equipment, _loop_2, _b, _c, rule;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        equipmentsRules = {
                            equipments: [],
                            rules: []
                        };
                        return [4 /*yield*/, this.equipmentService.getEstablishmentEquipments()];
                    case 1:
                        establishmentEquipments = _d.sent();
                        return [4 /*yield*/, this.equipmentService.getTypologyEquipments()];
                    case 2:
                        typologyEquipments = _d.sent();
                        return [4 /*yield*/, this.equipmentService.getEstablishmentRules()];
                    case 3:
                        establishmentRules = _d.sent();
                        return [4 /*yield*/, this.equipmentService.getTypologyRules()];
                    case 4:
                        typologyRules = _d.sent();
                        _loop_1 = function (equipment) {
                            if (equipments.find(function (val) { return val === equipment.slug; })) {
                                equipmentsRules.equipments.push(equipment.slug);
                            }
                        };
                        for (_i = 0, _a = establishmentEquipments.concat(typologyEquipments); _i < _a.length; _i++) {
                            equipment = _a[_i];
                            _loop_1(equipment);
                        }
                        _loop_2 = function (rule) {
                            if (equipments.find(function (val) { return val === rule.slug; })) {
                                equipmentsRules.rules.push(rule.slug);
                            }
                        };
                        for (_b = 0, _c = establishmentRules.concat(typologyRules); _b < _c.length; _b++) {
                            rule = _c[_b];
                            _loop_2(rule);
                        }
                        return [2 /*return*/, equipmentsRules];
                }
            });
        });
    };
    ListingsApiService.prototype.saveEstablishment = function (establishment) {
        var _this = this;
        return this.editEstablishment(establishment).toPromise()
            .then(function () {
            // Save equipments
            return _this.setEstablishmentEquipments(establishment.id, establishment.equipments).toPromise().then(function () {
                // Convert obj to array
                var paymentMethodConfigurations = [];
                for (var paymentMethod in establishment.paymentMethodConfigurations) {
                    paymentMethodConfigurations.push(establishment.paymentMethodConfigurations[paymentMethod]);
                }
                return _this.setEstablishmentPaymentMethodConfigurations(establishment.id, paymentMethodConfigurations).toPromise();
            });
        });
    };
    ListingsApiService.prototype.deleteEstablishment = function (id) {
        return this.http.delete(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + id);
    };
    // / TYPOLOGY
    ListingsApiService.prototype.getTypologies = function (establishmentId) {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies');
    };
    ListingsApiService.prototype.getTypology = function (establishmentId, typologyId) {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId);
    };
    ListingsApiService.prototype.deleteTypology = function (establishmentId, typologyId) {
        return this.http.delete(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId);
    };
    ListingsApiService.prototype.createTypology = function (typology) {
        return this.http.post(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies', {
            name: typology.name,
            type: typology.type,
            description: typology.description
        });
    };
    ListingsApiService.prototype.editTypology = function (typology) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id, {
            name: typology.name,
            type: typology.type,
            capacity: typology.capacity,
            availability: typology.availability,
            description: typology.description,
            minArrivalTime: typology.minArrivalTime,
            maxArrivalTime: typology.maxArrivalTime,
            minDepartureTime: typology.minDepartureTime,
            maxDepartureTime: typology.maxDepartureTime
        });
    };
    ListingsApiService.prototype.getTypologyCustomFields = function (establishmentId, typologyId) {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId + '/custom-fields')
            .toPromise();
    };
    ListingsApiService.prototype.setTypologyCustomFields = function (establishmentId, typologyId, fields) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId + '/custom-fields', {
            fields: fields
        })
            .toPromise();
    };
    ListingsApiService.prototype.setTypologyEquipments = function (typology, equipments) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/equipments', {
            equipments: equipments
        });
    };
    ListingsApiService.prototype.saveTypology = function (typology) {
        var _this = this;
        return this.editTypology(typology).toPromise()
            .then(function () {
            // Save equipments
            return _this.setTypologyEquipments(typology, typology.equipments).toPromise();
        });
    };
    // / PHOTO
    ListingsApiService.prototype.getPhotos = function (establishmentId, typologyId) {
        if (typologyId != -1) {
            return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId + '/photos');
        }
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/photos');
    };
    ListingsApiService.prototype.getLinkPhoto = function (path) {
        return this.apiUrlService.siteBaseUrl(true) + path;
    };
    ListingsApiService.prototype.getAvailableBedTypes = function () {
        return this.http.get(this.apiUrlService.baseUrl() + 'typologies/bed-types');
    };
    ListingsApiService.prototype.setTypologyBedTypes = function (typology, rooms) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/rooms', {
            rooms: rooms
        });
    };
    ListingsApiService.prototype.getTypologyBedTypes = function (typology) {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/rooms');
    };
    ListingsApiService.prototype.deletePhoto = function (id) {
        return this.http.delete(this.apiUrlService.siteBaseUrl() + 'photos/' + id);
    };
    ListingsApiService.prototype.getMainPhoto = function (establishment, typology) {
        if (typology === -1) {
            return this.apiUrlService.siteBaseUrl(true) + 'photos/main/' + establishment;
        }
        return this.apiUrlService.siteBaseUrl(true) + 'photos/main/' + establishment + '/' + typology;
    };
    ListingsApiService.prototype.updateOrderPhoto = function (list_id) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'photos/order', { photos: list_id });
    };
    ListingsApiService.prototype.setPhotoTags = function (photo, list_tags) {
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'photos/' + photo + '/tags', { tags: list_tags });
    };
    ListingsApiService.prototype.getTypologyFees = function (typology) {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/fees');
    };
    ListingsApiService.prototype.setTypologyFees = function (typology, fees) {
        fees = fees.map(function (fee) {
            fee = __assign({}, fee);
            if (fee.partType === 'monetary') {
                delete fee.percentage;
            }
            if (fee.partType === 'percentage') {
                delete fee.amount;
            }
            delete fee.partType;
            return fee;
        });
        return this.http.put(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + typology.establishmentId + '/typologies/' + typology.id + '/fees', {
            fees: fees
        });
    };
    ListingsApiService.prototype.getFees = function () {
        return this.http.get(this.apiUrlService.baseUrl() + 'fees');
    };
    return ListingsApiService;
}());
export { ListingsApiService };
