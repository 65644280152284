import * as i0 from "@angular/core";
var AlertService = /** @class */ (function () {
    function AlertService() {
        this.alerts = [];
    }
    AlertService.prototype.set = function (type, message, persistent) {
        if (persistent === void 0) { persistent = false; }
        this.clear();
        return this.add(type, message, persistent);
    };
    AlertService.prototype.add = function (type, message, persistent) {
        var _this = this;
        if (persistent === void 0) { persistent = false; }
        this.alerts.push({
            type: type,
            message: message,
            persistent: persistent,
            close: function () {
                return _this.close(_this);
            }
        });
    };
    AlertService.prototype.close = function (alert) {
        return this.closeIdx(this.alerts.indexOf(alert));
    };
    AlertService.prototype.closeIdx = function (index) {
        return this.alerts.splice(index, 1);
    };
    AlertService.prototype.clear = function () {
        this.alerts = [];
    };
    AlertService.prototype.clearTemp = function () {
        this.alerts = this.alerts.filter(function (obj) { return obj.persistent; });
        this.alerts = this.alerts.map(function (obj) { obj.persistent = false; return obj; });
    };
    AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
export { AlertService };
