<div class="modal-header">
    <h4 class="modal-title">{{ 'paymentMethods.STRIPE_ACCOUNT' | translate }} <i class="fas fa-spin fa-circle-notch" [hidden]="loaded"></i></h4>
</div>
<div class="modal-body">
    <div *ngIf="paymentMethodConfiguration !== null">
        <div class="row mb-3">
            <div class="col-12">
                <div class="form-group">
                    <label for="label">{{ 'paymentMethods.LABEL' | translate }}</label>
                    <input type="text" id="label" class="form-control" [(ngModel)]="paymentMethodConfiguration.name">
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <div class="form-group">
                    <label for="public-key">{{ 'paymentMethods.PUBLIC_KEY' | translate }}</label>
                    <input type="text" id="public-key" class="form-control" [(ngModel)]="paymentMethodConfiguration.parsedData.publicKey">
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <div class="form-group">
                    <label for="private-key">{{ 'paymentMethods.PRIVATE_KEY' | translate }}</label>
                    <input type="text" id="private-key" class="form-control" [(ngModel)]="paymentMethodConfiguration.parsedData.privateKey" placeholder="{{ paymentMethodConfiguration?.id ? ('paymentMethods.PRIVATE_KEY_NOTE' | translate) : '' }}">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
  <button [disabled]="!loaded" *ngIf="paymentMethodConfiguration?.id" type="button" class="btn btn-danger" (click)="deletePaymentMethodConfiguration(deletionConfirm)">{{ 'common.DELETE' | translate }}</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">{{ 'common.CLOSE' | translate }}</button>
  <button [disabled]="!loaded" type="button" class="btn btn-primary" (click)="setPaymentMethodConfiguration()">{{ 'common.CONFIRM' | translate }}</button>
</div>

<ng-template #deletionConfirm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ 'common.DELETION' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ 'common.DELETION_CONFIRM' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modal.dismiss()">{{ 'common.CLOSE' | translate }}</button>
        <button type="button" class="btn btn-danger" (click)="modal.close('delete')">{{ 'common.DELETE' | translate }}</button>
    </div>
</ng-template>