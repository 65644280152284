<div class="main-header">
    <div class="logo">
      <img src="./assets/images/logo.png" alt="">
    </div>

  <div class="menu-toggle" (click)="toggelSidebar()">
    <div></div>
    <div></div>
    <div></div>
  </div>

  <div class="">

  </div>
  <div style="margin: auto"></div>
  <div class="header-part-right">
    <div class="search-bar-container">
        <div (click)="searchService.searchOpen = true" class="site-selector">
          <img *ngIf="siteService.site" [src]="apiUrl.siteBaseUrl(true) + 'settings/resources/favicon'" [alt]="siteService.site.name">
          <span class="site-name" *ngIf="siteService.site">{{ siteService.site.name }}</span>
          <span class="site-name" *ngIf="!siteService.site">{{ 'header.SELECT_A_WEBSITE' | translate }}</span>
          <div class="chevron-down">
            <i class="far fa-chevron-down"></i>
          </div>
        </div>        
    </div>

    <div ngbDropdown [placement]="'bottom-right'" class="">
      <div class="badge-top-container">
        <i class="im i-Globe text-muted header-icon" id="dropdownBasic1" ngbDropdownToggle></i>
      </div>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="lang-dropdown" perfectScrollbar>
        <div class="dropdown-item d-flex" [ngClass]="translate.currentLang === lang.code ? 'selected' : ''" *ngFor="let lang of langs" (click)="selectLang(lang.code)"> 
          <div>
            {{ lang.name }}
          </div>
        </div>        
      </div>
    </div>

    <div ngbDropdown [placement]="'bottom-right'" class="user col">
      <div class="wrapped-user-image">
        <img src="./assets/images/no-avatar.png" id="userDropdown" ngbDropdownToggle alt="">
      </div>

      <div ngbDropdownMenu aria-labelledby="userDropdown">
        <div class="dropdown-header">
          <i class="im i-Lock-User mr-1"></i> {{ auth.user.email }}
        </div>
        <button class="dropdown-item" routerLink="/profile/profile">{{ 'titles.PROFILE' | translate }}</button>
        <button class="dropdown-item" routerLink="/profile/password">{{ 'titles.PASSWORD' | translate }}</button>
        <button class="dropdown-item" (click)="signout()">{{ 'user.SIGN_OUT' | translate }}</button>
      </div>
    </div>
    
  </div>

</div>