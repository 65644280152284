import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { PhototagsCategory } from '../../models/phototags-category';
import { PhototagsDb } from '../../inmemory-db/phototags';
import { ListingsApiService } from '../../../views/listings/listings-api.service';

@Component({
  selector: 'app-photo-tags',
  templateUrl: './photo-tags.component.html',
  styleUrls: ['./photo-tags.component.scss']
})
export class PhotoTagsComponent implements OnInit {
  private tagsList: PhototagsCategory;

  childTagsList = [];

  private selectedCategories = [];

  private selected = {
    tags: {}
  };

  private depthArray = [];

  public has = _.has;

  public tags = [];

  public listPhotosId = [];

  public listPhotosTags = [];

  public categoriesAtDepth = [];

  finalTags = [];

  photoId: number = null;

  constructor(public activeModal: NgbActiveModal, public listingsApi: ListingsApiService,) {
    this.tagsList = PhototagsDb.listPhotoTags;
    this.childTagsList = this.getChildTags(this.tagsList);
    this.updateDepthArray();
  }

  toggleCategory(category: string[]) {
    if (_.has(this.selected, category)) {
      _.unset(this.selected, category);
    } else {
      _.set(this.selected, category, {});
    }

    this.updateDepthArray();
  }

  updateDepthArray() {
    const depth = this.getMaxSelectedDepth();
    this.depthArray = [];
    for (let i = 0; i < depth; i++) {
      this.depthArray.push(i);
    }

    this.loadAndGetCategoriesAtDepth();
  }

  loadAndGetCategoriesAtDepth() {
    const depth = this.getMaxSelectedDepth();
    const depthList = [];
    for (let i = 0; i < depth; i++) {
      depthList.push([]);
    }
    const tagsList = [];
    this.getObjectsAtDepth(depthList, tagsList, this.tagsList, depth);
    this.finalTags = tagsList;
    depthList.shift();

    this.categoriesAtDepth = depthList;
    return this.categoriesAtDepth;
  }

  getObjectsAtDepth(depthList: any[], tagsList: any[], object: PhototagsCategory, stopAt: number, currentDepth: number = 0, dataDepth: string[] = []) {
    const objects = [];
    const data = dataDepth.slice();
    if (_.has(this.selected, data) || currentDepth == 0) {
      depthList[currentDepth].push(data);
    }
    data.push(object.slug);

    if (currentDepth + 1 >= stopAt) {
      return;
    }

    // Insert tags
    if (object.tagslist) {
      for (const tag of object.tagslist) {
        const dataTag = data.slice();
        dataTag.push(tag.slug);
        tagsList.push(dataTag);
      }
    }

    if (!object.subcategories) {
      return;
    }

    // Next depth : get all subcategories
    for (const subcategory of object.subcategories) {
      this.getObjectsAtDepth(depthList, tagsList, subcategory, stopAt, currentDepth + 1, data);
    }
  }

  public onSelect(item) {
    this.updateTags();
  }

  displayTag(tag: string[]) {
    const categories = tag.slice();
    categories.pop();
    return _.has(this.selected, categories);
  }

  ngOnInit() {
  }

  getMaxSelectedDepth() {
    return this.depthOf(this.selected);
  }

  depthOf(object) {
    let level = 1;
    let key;
    for (key in object) {
      if (!object.hasOwnProperty(key)) continue;

      if (typeof object[key] === 'object') {
        const depth = this.depthOf(object[key]) + 1;
        level = Math.max(depth, level);
      }
    }
    return level;
  }

  toggleTag(slug: string) {
    if (this.listPhotosId && this.listPhotosId.length > 1) {
      const booleanDelete = (this.tags.indexOf(slug) !== -1);
      for (const tagsphoto of this.listPhotosTags) {
        const index = tagsphoto.indexOf(slug);
        if (!booleanDelete && index === -1) {
          tagsphoto.push(slug);
        } else if (booleanDelete && index !== -1) {
          tagsphoto.splice(index, 1);
        }
      }
    }

    if (this.tags.indexOf(slug) !== -1) {
      this.tags.splice(this.tags.indexOf(slug), 1);
    } else {
      this.tags.push(slug);
    }

    this.updateTags();
  }

  getChildTags(categories: PhototagsCategory) {
    let tags = [];
    if (categories.tagslist && categories.tagslist.length > 0) {
      for (const element of categories.tagslist) {
        tags.push(element.slug);
      }
    }

    if (categories.subcategories) {
      for (const category of categories.subcategories) {
        const childTags = this.getChildTags(category);
        tags = tags.concat(childTags);
      }
    }

    return tags;
  }

  updateTags() {
    this.setPhotoTags(this.photoId, this.tags);
  }

  setPhotoTags(photo: number, tags: string[]) {
    if (!this.listPhotosId || this.listPhotosId.length <= 1) {
      return this.listingsApi.setPhotoTags(photo, tags).subscribe(() => {});
    }
    const promises = [];
    for (let i = 0; i < this.listPhotosId.length; i++) {
      promises.push(this.listingsApi.setPhotoTags(this.listPhotosId[i], this.listPhotosTags[i]).toPromise());
    }
    Promise.all(promises);
  }

  onRemove(item) {
    if (!this.listPhotosId || this.listPhotosId.length <= 1) {
      this.tags.splice(this.tags.indexOf(item.value), 1);
    } else {
      for (const tagsphoto of this.listPhotosTags) {
        tagsphoto.splice(tagsphoto.indexOf(item), 1);
      }
    }
    this.updateTags();
  }

  onAdd(item) {
    if (!this.listPhotosId || this.listPhotosId.length <= 1) {
      this.tags.push(item.value);
    } else {
      for (const tagsphoto of this.listPhotosTags) {
        if (tagsphoto.indexOf(item.value) === -1) {
          tagsphoto.push(item.value);
        }
      }
    }
    this.updateTags();
  }

  oneTagIsDisplay() {
    for (const tag of this.finalTags) {
      if (this.displayTag(tag)) {
        return true;
      }
    }
    return false;
  }
}
