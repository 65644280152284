import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from './local-store.service';
import { AuthService } from './auth.service';
import { TranslationMap } from '../models/language-map.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public availableLangs: string[] = ['fr', 'en'];

  private localePriority = ['en', 'fr'];

  private defaultLang: string = 'en';

  private _currentLang: string;

  public availableLangNames = { fr: 'Français', en: 'English' };

  get currentLang(): string {
    return this._currentLang;
  }

  set currentLang(lang: string) {
    if (this.availableLangs.indexOf(lang) === -1) {
      return;
    }

    this.localStore.setItem('lang', lang);

    this._currentLang = lang;
    this.translate.use(this.currentLang);
    moment.locale(this.currentLang);

    // Save the language if the user is connected
    if (this.auth.authenticated) {
      this.auth.setUserLang(lang);
    }
  }

  constructor(
    private translate: TranslateService,
    private localStore: LocalStoreService,
    private auth: AuthService
  ) {
  }

  initLanguages() {
    this.translate.addLangs(this.availableLangs);
    this.translate.setDefaultLang(this.defaultLang);

    const storedLang = this.localStore.getItem('lang');
    if (storedLang !== null) {
      this.currentLang = storedLang;
    } else {
      const browserLang = this.translate.getBrowserLang();
      if (this.availableLangs.indexOf(browserLang) !== -1) {
        this.currentLang = browserLang;
      } else {
        this.currentLang = this.defaultLang;
      }
    }
  }

  getTranslationFromMap(translationMap: TranslationMap) {
    if (!translationMap) return '';
    const lang = this.currentLang;
    if (lang != null && lang.length > 0) {
      if (translationMap[lang] != null && translationMap[lang].length > 0) return translationMap[lang];
    }
    for (const locale of this.localePriority) {
      if (translationMap[locale] != null && translationMap[locale].length > 0) {
        return translationMap[locale];
      }
    }
    return '';
  }
}
