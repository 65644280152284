import { OnInit } from '@angular/core';
import * as moment from 'moment';
import { ListingsApiService } from 'src/app/views/listings/listings-api.service';
import { CalendarApiService } from 'src/app/views/calendar/calendar-api.service';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditRangeModalContent } from 'src/app/views/calendar/calendar-creation/edit-range/edit-range.component';
import { Router } from '@angular/router';
import { LayoutService } from '../../services/layout.service';
import { CalendarService } from '../../services/calendar.service';
import { FormStepService } from '../../services/form-step.service';
var TypologyCalendarComponent = /** @class */ (function () {
    function TypologyCalendarComponent(listingsApi, calendarApi, calendarService, layout, modalService, formStepService, router) {
        this.listingsApi = listingsApi;
        this.calendarApi = calendarApi;
        this.calendarService = calendarService;
        this.layout = layout;
        this.modalService = modalService;
        this.formStepService = formStepService;
        this.router = router;
        this.lodash = _;
        /** List of days of the current date range (in YYYY-MM-DD format) */
        this.days = [];
        /** List of typologies of the current establishment */
        this.typologies = [];
        /** The calendar corresponding to the establishment */
        this.calendar = [];
        /** Id of the global rate */
        this.globalRate = 1;
        /** Loading fields, to display a loader for each field on the view */
        this.loadingFields = {};
        /** Fields to set as loaded after calendar loading */
        this.unsetAfterLoading = [];
        /** True if the component is being loaded */
        this.loading = false;
        /** True if the component has been loaded */
        this.firstTimeLoaded = false;
        /** True if the typology has been loaded */
        this.typologyLoaded = false;
        this.nextDateChange = null;
    }
    Object.defineProperty(TypologyCalendarComponent.prototype, "establishment", {
        get: function () {
            return this.calendarItem.establishment;
        },
        enumerable: true,
        configurable: true
    });
    TypologyCalendarComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(TypologyCalendarComponent.prototype, "dateRange", {
        get: function () {
            return this._dateRange;
        },
        set: function (value) {
            var _this = this;
            this._dateRange = value;
            if (this.loading) {
                // Load after
                this.nextDateChange = value;
                return;
            }
            this.loading = true;
            this.days = [];
            var start = moment(this.dateRange.start).startOf('day');
            var end = moment(this.dateRange.end).startOf('day');
            // For each day in our range
            for (var m = start; m.diff(end, 'days') <= 0; m.add(1, 'days')) {
                this.days.push(m.format('YYYY-MM-DD'));
            }
            if (!this.firstTimeLoaded) {
                // The component is loaded for the first date
                this.firstTimeLoaded = true;
                this.typologies = [];
                for (var typo in this.calendarItem.calendar) {
                    this.typologies.push(this.calendarItem.calendar[typo].typology);
                }
                this.typologyLoaded = true;
                this.calendarService.calendarUpdated.subscribe(function (calendar) {
                    // New calendar change
                    if (!calendar) {
                        return;
                    }
                    return _this.parseCalendar(calendar);
                });
                // // Also load typologies
                // this.listingsApi
                //     .getTypologies(this.establishment.id)
                //     .toPromise()
                //     .then(typologies => {
                //         this.typologies = typologies;
                //         return true;
                //     });
            }
        },
        enumerable: true,
        configurable: true
    });
    TypologyCalendarComponent.prototype.getBlockedIcalTooltip = function (ical) {
        return 'Bloqué par ' + ical.map(function (o) { return o.name; }).join(', ');
    };
    TypologyCalendarComponent.prototype.isClosed = function (val) {
        return val.closed.value;
    };
    TypologyCalendarComponent.prototype.isOpen = function (val) {
        return !this.isClosed(val);
    };
    TypologyCalendarComponent.prototype.isBlockedByIcal = function (val) {
        return val.closed.ical && val.closed.ical.length > 0;
    };
    TypologyCalendarComponent.prototype.isOpenedByIcal = function (val) {
        return !this.isBlockedByIcal(val);
    };
    TypologyCalendarComponent.prototype.isLoading = function (typology, rate, day) {
        return _.has(this.loadingFields, ['typology-' + typology, 'rate-' + rate, day, 'closed'].join('.'));
    };
    TypologyCalendarComponent.prototype.loadCalendar = function () {
        var _this = this;
        return this.calendarApi
            .getCalendar([this.establishment.id], this.days[0], this.days[this.days.length - 1])
            .toPromise()
            .then(function (calendar) {
            // Load calendar data
            return _this.parseCalendar(calendar.calendar);
        });
    };
    TypologyCalendarComponent.prototype.parseCalendar = function (rawCalendar) {
        var calendar = {};
        for (var _i = 0, rawCalendar_1 = rawCalendar; _i < rawCalendar_1.length; _i++) {
            var el = rawCalendar_1[_i];
            calendar[el.establishment.id] = {};
            for (var typoId in el.calendar) {
                calendar[el.establishment.id][typoId] = {};
                for (var rateId in el.calendar[typoId].calendar) {
                    calendar[el.establishment.id][typoId][rateId] = el.calendar[typoId].calendar[rateId].calendar;
                }
            }
        }
        var _loop_1 = function (typology) {
            var typologyObject = {
                typology: typology,
                configured: _.has(calendar, [
                    this_1.establishment.id,
                    typology.id,
                    this_1.globalRate
                ]),
                rates: _.get(calendar, [this_1.establishment.id, typology.id, this_1.globalRate], {})
            };
            var typologyCalendar = _.find(this_1.calendar, function (item) {
                return item.typology.id == typology.id;
            });
            if (typologyCalendar) {
                // Merge
                _.merge(typologyCalendar, typologyObject);
            }
            else {
                // Push
                this_1.calendar.push(typologyObject);
            }
        };
        var this_1 = this;
        for (var _a = 0, _b = this.typologies; _a < _b.length; _a++) {
            var typology = _b[_a];
            _loop_1(typology);
        }
        for (var _c = 0, _d = this.unsetAfterLoading; _c < _d.length; _c++) {
            var unset = _d[_c];
            _.unset(this.loadingFields, unset);
        }
        this.loading = false;
        return true;
    };
    TypologyCalendarComponent.prototype.setAuto = function (typology, rate, date, type) {
        var _this = this;
        var data = {
            date: date
        };
        data[type] = null;
        _.set(this.loadingFields, ['typology-' + typology, 'rate-' + rate, date, type], true);
        this.calendarApi
            .setCalendar(typology, rate, data)
            .toPromise()
            .then(function () {
            _this.unsetAfterLoading.push([
                'typology-' + typology,
                'rate-' + rate,
                date,
                type
            ]);
            return _this.loadCalendar();
        })
            .catch(function (e) { });
    };
    TypologyCalendarComponent.prototype.configureCalendar = function (typologyId) {
        this.formStepService.resetForm();
        this.formStepService.firstStep = 'calendar';
        this.formStepService.returnUrl = null;
        this.router.navigateByUrl('/calendar/establishments/'
            + this.establishment.id
            + '/typologies/'
            + typologyId);
    };
    TypologyCalendarComponent.prototype.updateCalendar = function (typology, rate, date, type, objOldValue, value) {
        var _this = this;
        var data = {
            date: date
        };
        var oldValue = objOldValue.value;
        if (isNaN(value)) {
            objOldValue.value = 0;
            setTimeout(function () {
                objOldValue.value = oldValue;
            }, 0);
            return;
        }
        var newValue = value;
        switch (type) {
            case 'price':
                newValue = Math.round(value * 100) / 100;
                if (newValue != value) {
                    objOldValue.value = 0;
                    setTimeout(function () {
                        objOldValue.value = newValue;
                    }, 0);
                }
                break;
            case 'minimumStay':
                if (value <= 0) {
                    objOldValue.value = 0;
                    setTimeout(function () {
                        objOldValue.value = oldValue;
                    }, 0);
                    return;
                }
                newValue = value;
                break;
        }
        // Set the new value
        objOldValue.value = newValue;
        // Set it in calendar data to send
        data[type] = newValue;
        _.set(this.loadingFields, ['typology-' + typology, 'rate-' + rate, date, type], true);
        this.calendarApi
            .setCalendar(typology, rate, data)
            .toPromise()
            .then(function () {
            _this.unsetAfterLoading.push([
                'typology-' + typology,
                'rate-' + rate,
                date,
                type
            ]);
            return _this.loadCalendar();
        })
            .catch(function (e) {
            _.unset(_this.loadingFields, [
                'typology-' + typology,
                'rate-' + rate,
                date,
                type
            ]);
            objOldValue.value = 0;
            setTimeout(function () {
                objOldValue.value = oldValue;
            }, 0);
        });
    };
    TypologyCalendarComponent.prototype.toggleStatus = function (typology, rate, date) {
        var _this = this;
        var typo = _.find(this.calendar, function (item) {
            return item.typology.id == typology;
        });
        if (typo.typology.hasExternalConnection)
            return;
        var data = {
            date: date,
            closed: !typo.rates[date].closed.value
        };
        _.set(this.loadingFields, ['typology-' + typology, 'rate-' + rate, date, 'closed'], true);
        this.calendarApi
            .setCalendar(typology, rate, data)
            .toPromise()
            .then(function () {
            typo.rates[date].closed.value = !typo.rates[date].closed.value;
            _.unset(_this.loadingFields, [
                'typology-' + typology,
                'rate-' + rate,
                date,
                'closed'
            ]);
            return _this.loadCalendar();
        });
    };
    TypologyCalendarComponent.prototype.rangeModal = function (typology, rate, type) {
        var _this = this;
        var modalRef = this.modalService.open(EditRangeModalContent, {
            centered: true
        });
        modalRef.componentInstance.typologyId = typology;
        modalRef.componentInstance.rateId = rate;
        modalRef.componentInstance.type = type;
        return modalRef.result
            .then(function () {
            return _this.loadCalendar();
        })
            .catch(function () { });
    };
    return TypologyCalendarComponent;
}());
export { TypologyCalendarComponent };
