import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LocalStoreService } from './local-store.service';
var FormStepService = /** @class */ (function () {
    function FormStepService(store, router, location) {
        this.store = store;
        this.router = router;
        this.location = location;
        this.steps = [
            {
                name: 'site',
                label: 'Site de réservation',
                icon: 'fa-globe'
            },
            {
                name: 'establishment',
                label: 'listings.establishments.ESTABLISHMENT',
                icon: 'fa-hotel'
            },
            {
                name: 'typology',
                label: 'listings.typologies.TYPOLOGY',
                icon: 'fa-bed'
            },
            {
                name: 'calendar',
                label: 'calendar.CALENDAR',
                icon: 'fa-calendar-alt'
            }
        ];
        this.creationFormData = {};
        this.returnUrl = null;
        this.loadFromStorage();
    }
    FormStepService.prototype.loadFromStorage = function () {
        this.creationFormData = this.store.getItem('creationFormData');
        if (this.creationFormData == null) {
            this.creationFormData = {};
        }
    };
    FormStepService.prototype.saveToStorage = function () {
        this.store.setItem('creationFormData', this.creationFormData);
    };
    FormStepService.prototype.resetForm = function () {
        this.store.setItem('creationFormData', null);
    };
    Object.defineProperty(FormStepService.prototype, "firstStep", {
        get: function () {
            return this.steps[this.store.getItem('creationFormFirstStep')].name;
        },
        set: function (stepName) {
            var stepIndex = this.steps.findIndex(function (step) { return step.name == stepName; });
            if (stepIndex === -1) {
                throw new Error('Step ' + stepName + ' not found');
            }
            this.store.setItem('creationFormFirstStep', stepIndex);
        },
        enumerable: true,
        configurable: true
    });
    FormStepService.prototype.initFormStep = function (stepName) {
        var _this = this;
        this.loadFromStorage();
        // Load first step
        var savedFirstStep = this.store.getItem('creationFormFirstStep');
        if (savedFirstStep !== null) {
            this.firstStepPosition = savedFirstStep;
        }
        this.steps.forEach(function (step, index) {
            if (step.name !== stepName) {
                return;
            }
            if (savedFirstStep === null) {
                _this.firstStepPosition = index;
                _this.store.setItem('creationFormFirstStep', index);
            }
            _this.currentStepPosition = index;
        });
    };
    return FormStepService;
}());
export { FormStepService };
