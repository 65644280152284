import { AgmCoreModule } from '@agm/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { Daterangepicker } from 'ng2-daterangepicker';
import { ColorPickerModule } from 'ngx-color-picker';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RoomsSelectorService } from './shared/components/rooms-selector/rooms-selector.service';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { ApiUrlService } from './shared/services/api-url.service';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { ConfigService } from './shared/services/config.service';
import { FormStepService } from './shared/services/form-step.service';
import { LayoutService } from './shared/services/layout.service';
import { SiteService } from './shared/services/site.service';
import { SharedModule } from './shared/shared.module';
import { ListingsApiService } from './views/listings/listings-api.service';
import { StripeAccountComponent } from './views/listings/stripe-account/stripe-account.component';
import { PaymentMethodConfigurationsPropagationComponent } from './views/listings/payment-method-configurations-propagation/payment-method-configurations-propagation.component';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

export function initializeApp(
  appConfig: ConfigService,
  siteService: SiteService,
): () => Promise<any> {
  return async () => {
    try {
      await appConfig.load();
      await siteService.loadEnvironment();
      await siteService.updateAvailableSites();
      await siteService.selectSite();
    } catch (e) {
      console.error(e);
    }
    return true;
  };
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    ConfigService.settings.apiBaseUrl + 'lang/',
    ''
  );
}

@NgModule({
  declarations: [AppComponent, StripeAccountComponent, PaymentMethodConfigurationsPropagationComponent],
  entryComponents: [StripeAccountComponent, PaymentMethodConfigurationsPropagationComponent],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      // compiler: {
      //     provide: TranslateCompiler,
      //     useClass: TranslateMessageFormatCompiler
      // }
    }),
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, {
      passThruUnknownUrl: true
    }),
    AppRoutingModule,
    ColorPickerModule,
    FormsModule, 
    NgbTooltipModule, 
    NgbModule,
    SweetAlert2Module.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAPS_API_KEY,
      libraries: ['places']
    }),
    Daterangepicker,
    Nl2BrPipeModule,
    NgSelectModule,
    CKEditorModule
  ],
  providers: [
    ConfigService,
    SiteService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService, SiteService],
      multi: true
    },
    ApiUrlService,
    ListingsApiService,
    FormStepService,
    RoomsSelectorService,
    LayoutService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
