import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AlertService } from './alert.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./alert.service";
var RouteService = /** @class */ (function () {
    function RouteService(router, route, translate, title, alert) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.translate = translate;
        this.title = title;
        this.alert = alert;
        this._currentRoute = null;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.route; }), map(function (route) {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }), filter(function (route) { return route.outlet === 'primary'; }), mergeMap(function (route) { return route.data; }))
            .subscribe(function (data) {
            // Set title
            var title = 'Anytime';
            if (data.title) {
                _this.translate.get(data.title)
                    .subscribe(function () {
                    title = _this.translate.instant(data.title) + ' - ' + title;
                    _this.title.setTitle(title);
                });
            }
            else {
                _this.title.setTitle(title);
            }
            _this._currentRoute = {
                name: data.name || '',
                mainNoPadding: data.mainNoPadding || false,
                hideFooter: data.hideFooter || false
            };
            _this.alert.clearTemp();
        });
    }
    Object.defineProperty(RouteService.prototype, "currentRoute", {
        get: function () {
            return this._currentRoute;
        },
        enumerable: true,
        configurable: true
    });
    RouteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouteService_Factory() { return new RouteService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.Title), i0.ɵɵinject(i4.AlertService)); }, token: RouteService, providedIn: "root" });
    return RouteService;
}());
export { RouteService };
