var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { ListingsApiService } from 'src/app/views/listings/listings-api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SiteService } from '../../services/site.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeAccountComponent } from 'src/app/views/listings/stripe-account/stripe-account.component';
import { EstablishmentSelectorComponent } from '../establishment-selector/establishment-selector.component';
import { PaymentMethodConfigurationsPropagationComponent } from 'src/app/views/listings/payment-method-configurations-propagation/payment-method-configurations-propagation.component';
var PaymentMethodConfigurationsEditorComponent = /** @class */ (function () {
    function PaymentMethodConfigurationsEditorComponent(localeId, listingsApi, translate, toastr, site, modalService) {
        this.localeId = localeId;
        this.listingsApi = listingsApi;
        this.translate = translate;
        this.toastr = toastr;
        this.site = site;
        this.modalService = modalService;
        this.allPaymentMethodConfigurations = {};
        this.selectedPaymentMethods = {
            'paymentCardData': false,
            'stripe': false
        };
        this.loaded = false;
    }
    PaymentMethodConfigurationsEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadAllPaymentMethodConfigurations().then(function () {
            for (var paymentMethod in _this.establishment.paymentMethodConfigurations) {
                _this.selectedPaymentMethods[paymentMethod] = true;
            }
        });
    };
    PaymentMethodConfigurationsEditorComponent.prototype.openPropagationModal = function () {
        var _this = this;
        var establishmentSelectorModalRef = this.modalService.open(EstablishmentSelectorComponent, { size: 'lg' });
        establishmentSelectorModalRef.componentInstance.excludedEstablishmentIds = [this.establishment.id];
        establishmentSelectorModalRef.componentInstance.multiple = true;
        // modalRef.componentInstance.paymentMethodConfigurationId = paymentMethodConfigurationId;
        establishmentSelectorModalRef.result.then(function (esmResult) {
            if (esmResult) {
                var propagationModalRef = _this.modalService.open(PaymentMethodConfigurationsPropagationComponent, { size: 'lg' });
                propagationModalRef.componentInstance.establishments = esmResult.selectedEstablishments;
                // Get paymentMethodConfigurations from the current state of the establishment
                var paymentMethodConfigurations = [];
                for (var paymentMethod in _this.establishment.paymentMethodConfigurations) {
                    paymentMethodConfigurations.push(_this.establishment.paymentMethodConfigurations[paymentMethod]);
                }
                propagationModalRef.componentInstance.paymentMethodConfigurations = paymentMethodConfigurations;
                propagationModalRef.result.then(function (pmResult) {
                    if (pmResult == "success") {
                        _this.toastr.success(_this.translate.instant('establishments.UPDATED'), _this.translate.instant('common.SUCCESS'));
                    }
                });
            }
        }, function (reason) {
            console.error(reason);
        });
    };
    PaymentMethodConfigurationsEditorComponent.prototype.switchPaymentCardData = function () {
        if (this.selectedPaymentMethods.paymentCardData) {
            this.selectedPaymentMethods.paymentCardData = false;
            delete this.establishment.paymentMethodConfigurations.paymentCardData;
        }
        else {
            this.establishment.paymentMethodConfigurations.paymentCardData = __assign({}, this.allPaymentMethodConfigurations.paymentCardData[0]);
            this.selectedPaymentMethods.paymentCardData = true;
        }
    };
    PaymentMethodConfigurationsEditorComponent.prototype.switchStripe = function () {
        if (this.selectedPaymentMethods.stripe) {
            this.selectedPaymentMethods.stripe = false;
            delete this.establishment.paymentMethodConfigurations.stripe;
        }
        else {
            this.establishment.paymentMethodConfigurations.stripe = {
                id: null,
                paymentMethod: 'stripe',
                name: '',
                parsedData: {}
            };
            this.selectedPaymentMethods.stripe = true;
        }
    };
    PaymentMethodConfigurationsEditorComponent.prototype.openStripeAccountModal = function (edit) {
        var _this = this;
        if (edit === void 0) { edit = false; }
        var paymentMethodConfigurationId = null;
        if (edit && this.establishment.paymentMethodConfigurations.stripe.id) {
            paymentMethodConfigurationId = this.establishment.paymentMethodConfigurations.stripe.id;
        }
        var modalRef = this.modalService.open(StripeAccountComponent, { size: 'lg' });
        modalRef.componentInstance.paymentMethodConfigurationId = paymentMethodConfigurationId;
        modalRef.result.then(function (result) {
            if (result == "refresh") {
                _this.loadAllPaymentMethodConfigurations();
            }
        }, function (reason) {
            console.error(reason);
        });
    };
    // Load the payment method configurations of the paymentMethod provided
    PaymentMethodConfigurationsEditorComponent.prototype.loadAllPaymentMethodConfigurations = function () {
        var _this = this;
        return Promise.all([
            this.listingsApi.getPaymentMethodConfigurations()
                .toPromise()
                .then(function (paymentMethodConfigurations) {
                _this.allPaymentMethodConfigurations = {};
                for (var _i = 0, paymentMethodConfigurations_1 = paymentMethodConfigurations; _i < paymentMethodConfigurations_1.length; _i++) {
                    var paymentMethodConfiguration = paymentMethodConfigurations_1[_i];
                    if (typeof _this.allPaymentMethodConfigurations[paymentMethodConfiguration.paymentMethod] == 'undefined') {
                        _this.allPaymentMethodConfigurations[paymentMethodConfiguration.paymentMethod] = [];
                    }
                    _this.allPaymentMethodConfigurations[paymentMethodConfiguration.paymentMethod].push(paymentMethodConfiguration);
                }
                // console.log('allPaymentMethodConfigurations', this.allPaymentMethodConfigurations);
            })
        ])
            .then(function () {
            _this.loaded = true;
        });
    };
    PaymentMethodConfigurationsEditorComponent.prototype.getCurrencySymbol = function () {
        return getCurrencySymbol(this.site.currency, 'wide', this.localeId);
    };
    return PaymentMethodConfigurationsEditorComponent;
}());
export { PaymentMethodConfigurationsEditorComponent };
