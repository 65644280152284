import { Component, Input, OnInit } from '@angular/core';
import { ListingsApiService } from '../../../views/listings/listings-api.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Establishment } from 'src/app/shared/models/establishment.model';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-establishment-selector',
  templateUrl: './establishment-selector.component.html',
  styleUrls: ['./establishment-selector.component.scss']
})
export class EstablishmentSelectorComponent implements OnInit {
  @Input() excludedEstablishmentIds: number[] = [];
  @Input() checkedEstablishmentIds: number[] = [];
  @Input() multiple: boolean;

  loading: boolean = true;
  establishments: Establishment[];

  establishmentCheckboxes: {[establishmentId: number]: boolean} = {};

  constructor(
    private listingsApi: ListingsApiService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    protected language: LanguageService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.listingsApi.getEstablishments().toPromise()
      .then((establishments: Establishment[]) => {
        this.establishments = establishments.filter(e => { return this.excludedEstablishmentIds.includes(e.id) == false }); // Exclude source establishment
        for (let establishment of this.establishments) {
          this.establishmentCheckboxes[establishment.id] = this.checkedEstablishmentIds.includes(establishment.id);
        }
      });
  }

  selectAll() {
    for (let establishmentId in this.establishmentCheckboxes) {
      this.establishmentCheckboxes[establishmentId] = true;
    }
  }
  unselectAll() {
    for (let establishmentId in this.establishmentCheckboxes) {
      this.establishmentCheckboxes[establishmentId] = false;
    }
  }

  confirmSelection() {
    // Get an array of establishment ids matching the checked checkboxes
    console.log(this.establishmentCheckboxes);
    let checkedEstablishmentCheckboxes = [];
    for (let establishmentId in this.establishmentCheckboxes) {
      if (this.establishmentCheckboxes[establishmentId]) {
        checkedEstablishmentCheckboxes.push(parseInt(establishmentId));
      }
    }
    
    let selectedEstablishments = this.establishments.filter(e => { return checkedEstablishmentCheckboxes.includes(e.id) == true }); 

    this.activeModal.close({
      selectedEstablishments
    });
  }

  close() {
    this.activeModal.close();
  }
}
